import * as apiService from '../apis';
import { REQUEST_TIMEOUT_MESSAGE_TITLE } from '../constants/message';

const fetchTimeoutHandler = fetchRequest =>
  Promise.race([
    fetchRequest,
    new Promise((resolve, reject) =>
      setTimeout(
        () =>
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ errorCode: 408, message: REQUEST_TIMEOUT_MESSAGE_TITLE }),
        30000
      )
    ),
  ]);

export { apiService, fetchTimeoutHandler };
