// @flow
import { get } from 'lodash';

import { changeCollectionOption } from '../../../../store/orm/Collection/actions';
import type { ChangeCollectionOptionOptions } from '../../../../apis';
import type { AuthorizedCollection } from '../../../../types/Collection';
import { getNotificationDetails } from '../../../../models/collection';

type DataProps = {
  notificationDetails?: Object,
};

export const cancelCollection = (
  collection: AuthorizedCollection,
  data: DataProps,
  options: ChangeCollectionOptionOptions
) => {
  const notificationDetails = get(
    data,
    'notificationDetails',
    getNotificationDetails(collection)
  );

  return changeCollectionOption(
    collection.collectionCode,
    {
      notificationDetails,
    },
    options
  );
};
