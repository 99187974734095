// @flow

import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import type { AuthorizedCollection } from '../../../../types/Collection';
import { SFP, trackable } from '../../../../constants/analytics';
import * as MESSAGES from '../../../../constants/message';
import { fetchActionsDatesByCode } from '../../actions';
import { SFP_STEPS } from '../../steps';
import { withCollectionUpdate } from '../../hocs';
import { withCollection } from '../../../Collection/hocs';
import SelectDayStep from './SelectDayStep';
import DescribeSafePlaceStep from './DescribeSafePlaceStep';
import SelectSafePlaceStep from './SelectSafePlaceStep';
import { collectFromSafePlace } from './actions';
import UploadSafePlacePhotoStep from './UploadSafePlacePhotoStep';
import GetContactDetailsStep from './GetContactDetailsStep';
import {
  getCollectionOptionHeaderControlActions,
  getCollectionOptionSpeedDealControlActions,
} from '../../../controlActions';
import withFetch from '../../../../HOCs/withFetch';

type Props = {
  collection: AuthorizedCollection,
  dates: Array<string>,
  updateCollection: Function,
  onSubmitFailure: Function,
};

const SafePlaceComponent = ({
  dates,
  collection,
  updateCollection,
  onSubmitFailure,
}: Props) => (
  <Wizard
    onSubmit={updateCollection}
    initialStep={SFP_STEPS.SELECT_SAFE_PLACE}
    onSubmitFailure={onSubmitFailure}
  >
    <Step
      stepId={SFP_STEPS.SELECT_DAY}
      dates={dates}
      component={SelectDayStep}
      collection={collection}
      collectionDate={collection.collectionDate}
    />
    <Step
      stepId={SFP_STEPS.SELECT_SAFE_PLACE}
      dates={dates}
      component={SelectSafePlaceStep}
      collection={collection}
    />
    <Step
      stepId={SFP_STEPS.DESCRIBE_SAFE_PLACE}
      dates={dates}
      component={DescribeSafePlaceStep}
      collection={collection}
    />
    <Step
      stepId={SFP_STEPS.UPLOAD_SAFE_PLACE_PHOTO}
      dates={dates}
      component={UploadSafePlacePhotoStep}
      collection={collection}
    />
    <Step
      stepId={SFP_STEPS.GET_CONTACTS}
      component={GetContactDetailsStep}
      collection={collection}
    />
  </Wizard>
);

const SafePlace = compose(
  withRouter,
  withCollection,
  withFetch(({ match }) =>
    fetchActionsDatesByCode(
      match.params.collectionId,
      ENUM.COLLECTION_ACTION_CODE.SFP
    )
  ),
  withProps(props => ({
    dates: props.fetchedData,
  })),
  withCollectionUpdate({
    actionCode: ENUM.COLLECTION_ACTION_CODE.SFP,
    changeCollectionAction: collectFromSafePlace,
  }),
  withTrackProps({
    updateCollection: SFP.SUBMIT,
  }),
  withPageControl({
    title: MESSAGES.COLLECT_FROM_SAFE_PLACE,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(SFP),
    headerMenuActions: getCollectionOptionHeaderControlActions,
    speedDealActions: getCollectionOptionSpeedDealControlActions,
  })
)(SafePlaceComponent);

export default SafePlace;
