// @flow
import type { Location } from '@dpdgroupuk/fmx-ui/types/Location';

import api from './api';

export const getLocations = (postcode: string) => {
  return api.get<_, Array<Location>>({
    path: `/locations?page=100&postcode=${postcode}`,
  });
};
