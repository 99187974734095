export const getSessionId = () => {
  const name = 'FMCSessionId=';
  const decodedCookieArray = decodeURIComponent(document.cookie).split(';');
  for (let i = 0; i < decodedCookieArray.length; i++) {
    let c = decodedCookieArray[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
