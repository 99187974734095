// @flow
import { createAsyncDataReducer } from '@dpdgroupuk/redux-action-creator';
import type { AsyncDataState } from '@dpdgroupuk/redux-action-creator';

import type { SearchParcel } from '@dpdgroupuk/fmx-ui/types';

import { ACTIONS } from '../actions/collections';

export type State = AsyncDataState<SearchParcel>;

const reducer = createAsyncDataReducer(
  ACTIONS.FETCH_SEARCH_COLLECTION_BY_REFERENCE
);

export default reducer;
