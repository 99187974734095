import * as React from 'react';
import { Loadable } from '@dpdgroupuk/fmx-ui/components/Loader';

import { useCollectionData, usePostcodeSubmitCallback } from '../hooks';
import TrackingLight from '../pages/LightView';
import TrackingFull from '../pages/FullView';
import type {
  Collection,
  AuthorizedCollection,
} from '../../../types/Collection';
import ErrorScreen from '../../../pages/ErrorScreen';

const TrackingPage = () => {
  const collectionData = useCollectionData();
  const onPostcodeSubmit = usePostcodeSubmitCallback();

  const asyncDataProps = {
    promiseFn: collectionData,
    deferFn: onPostcodeSubmit,
    persistData: true,
  };

  return (
    <Loadable asyncDataProps={asyncDataProps} errorScreen={ErrorScreen}>
      {(collection: Collection & AuthorizedCollection, { run }) => {
        if (collection && collection.collectionDetails) {
          return <TrackingFull collection={collection} />;
        } else {
          return <TrackingLight collection={collection} onSubmit={run} />;
        }
      }}
    </Loadable>
  );
};

export default TrackingPage;
