// @flow
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncAction } from '@dpdgroupuk/redux-action-creator';
import { apiService, fetchTimeoutHandler } from '../../../services';
import type { ChangeCollectionOptionOptions } from '../../../apis';

type ActionTypes = {|
  FETCH_COLLECTION_BY_CODE: AsyncAction,
  CHANGE_COLLECTION_OPTION: AsyncAction,
  REMOVE_COLLECTION_BY_CODE: string,
|};

export const ACTIONS = createActionTypes<ActionTypes>('COLLECTION', {
  FETCH_COLLECTION_BY_CODE: createAsyncActionTypes('FETCH_COLLECTION_BY_CODE'),
  CHANGE_COLLECTION_OPTION: createAsyncActionTypes('CHANGE_COLLECTION_OPTION'),
  REMOVE_COLLECTION_BY_CODE: 'REMOVE_COLLECTION_BY_CODE',
});

export const fetchCollectionByCode = createAsyncAction(
  (collectionCode: string) =>
    fetchTimeoutHandler(
      apiService.getCollectionByCollectionCode(collectionCode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_COLLECTION_BY_CODE
);

export const changeCollectionOption = (
  collectionCode: string,
  data: any,
  options: ChangeCollectionOptionOptions
) =>
  fetchTimeoutHandler(
    apiService.changeCollectionOption(collectionCode, data, options)
  ).then(({ data }) => data);

export const removeCollectionByCode = (collectionCode: string) =>
  createPayloadAction(ACTIONS.REMOVE_COLLECTION_BY_CODE, collectionCode);
