// @flow

import * as React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { SAFE_PLACE_TYPE } from '@dpdgroupuk/redback-enums';
import { getSafePlaceIcon } from '../model';

const useStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: typography.pxToRem(15),
  },
  title: {
    fontSize: typography.pxToRem(19),
    fontWeight: 400,
    lineHeight: 0.85,
    letterSpacing: 0.44,
    textAlign: 'center',
    marginRight: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  button: {
    color: '#1d9ad0',
    fontSize: typography.pxToRem(13),
    letterSpacing: 0.44,
    textAlign: 'center',
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(11),
    },
  },
  icon: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(25),
    },
    fontSize: typography.pxToRem(30),
    marginRight: typography.pxToRem(20),
    '& .safePlaceIcon': {
      fill: palette.primary.main,
    },
  },
  [`${SAFE_PLACE_TYPE.OTH}`]: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(35),
    },
    fontSize: typography.pxToRem(40),
    marginRight: typography.pxToRem(10),
  },
}));

type Props = {
  onSafePlaceChange: Function,
  data: Object,
};

export default ({ onSafePlaceChange, data: { safePlace } }: Props) => {
  const styles = useStyles();
  const { name, id } = safePlace;
  const IconComponent = React.useMemo(() => getSafePlaceIcon(id), [id]);

  return (
    <>
      <div className={styles.mainWrapper}>
        <IconComponent className={clsx(styles.icon, styles[id])} />
        <Typography className={styles.title}>{name}</Typography>
        <Link
          component="button"
          onClick={onSafePlaceChange}
          className={styles.button}
        >
          CHANGE
        </Link>
      </div>
    </>
  );
};
