// @flow

import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import ReferenceEntryWrapper from '@dpdgroupuk/fmx-ui/components/ReferenceEntryWrapper';
import {
  validatePostcodeWithMessage,
  validateCollectionReferenceNumberWithMessage,
  required,
} from '@dpdgroupuk/fmx-ui/utils/validation';

import { normalizeReferenceNumber } from '../models';
import { REFERENCE_ENTRY } from '../../../constants/analytics';
import { REFERENCE_ENTRY_TITLE, SUBMIT } from '../../../constants/message';

type Props = {
  onReferenceSubmit: () => Promise<any>,
  handleSubmit: Function => Function,
  valid: boolean,
};

const validateReference = [
  required(),
  validateCollectionReferenceNumberWithMessage,
];
const validatePostcode = [validatePostcodeWithMessage];

const ReferenceEntryForm = ({
  onReferenceSubmit,
  handleSubmit,
  valid,
}: Props) => {
  const tracker = useTracker();
  // fix issue when caret position goes to the end of normalized redux-form input
  const saveRefNumberCaretPosition = (event: any) => {
    const {
      target: { selectionStart, value },
    } = event;

    if (window.queueMicrotask && selectionStart < value.length) {
      let caret = selectionStart;
      const spacePosition = [4, 9, 14, 19, 24, 29];
      if (spacePosition.includes(selectionStart)) {
        ++caret;
      }
      window.queueMicrotask(() => {
        event.target.setSelectionRange(caret, caret);
      });
    }
  };

  const onPostcodeEntry = React.useCallback(() => {
    tracker.logEvent(REFERENCE_ENTRY.ENTER_POSTCODE);
  }, [tracker]);

  const onRefEntry = React.useCallback(() => {
    tracker.logEvent(REFERENCE_ENTRY.ENTER_REFERENCE);
  }, [tracker]);

  return (
    <ReferenceEntryWrapper
      title={REFERENCE_ENTRY_TITLE}
      buttonText={SUBMIT}
      onSubmit={handleSubmit(onReferenceSubmit)}
      valid={valid}
      referenceNumberInput={
        <Field
          component={InputFormField}
          name="referenceNumber"
          placeholder="Tracking Number, Calling card number"
          validate={validateReference}
          normalize={normalizeReferenceNumber}
          fullWidth
          onChange={saveRefNumberCaretPosition}
          onBlur={onRefEntry}
        />
      }
      postcodeInput={
        <Field
          fullWidth
          component={InputFormField}
          name="postcode"
          placeholder="Postcode"
          validate={validatePostcode}
          inputProps={{
            maxLength: 8,
          }}
          onBlur={onPostcodeEntry}
        />
      }
    />
  );
};

export default reduxForm({
  form: 'reference',
})(ReferenceEntryForm);
