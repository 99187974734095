// @flow

import * as React from 'react';
import { useAsync } from 'react-async';

import { ConfigContext, defaultContextValue } from './ConfigContext';

const parse = (config): { [string]: any } =>
  Object.keys(config).reduce((acc, key) => {
    acc[key] = JSON.parse(config[key].asString());
    return acc;
  }, {});

type Props = {
  remoteConfig: any,
  children: React.Node,
};

export const ConfigProvider = ({ remoteConfig, children }: Props) => {
  const fetchConfig = React.useCallback(async () => {
    await remoteConfig.fetchAndActivate();
    return parse(remoteConfig.getAll());
  }, [remoteConfig]);

  const { data } = useAsync(fetchConfig);
  const contextValue = React.useMemo(
    () => ({ ...defaultContextValue, ...data }),
    [data]
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};
