export const HOME = 'HOME';
export const CLOSE = 'CLOSE';
export const ERROR = 'Error';
export const MORE_COOL_STUFF = 'More cool stuff';
export const SUBMIT = 'Submit';
export const GET_DPD_APP = 'Get the DPD App';
export const REAL_TIME_COLLECTION_UPDATE = 'Real time collection update';
export const COLLECTION_INFO = 'Collection info';
export const LEARN_MORE_ABOUT_DRIVER = 'Learn more about driver';
export const LEARN_MORE_ABOUT_THE_DRIVER = 'Learn More About the Driver';
export const TRACK_ANOTHER_COLLECTION = 'Track Another Collection';
export const BACK_TO_DPD_SITE = 'Back to DPD Site';
export const CHANGE_COLLECTION = 'Change Collection';
export const COLLECTION_DETAILS = 'Collection Details';
export const VIEW_TRACKING = 'View Tracking';
export const COLLECTION_OPTIONS = 'Your collection options';
export const DO_SOMETHING_DIFFERENT = 'Do something different';
export const CHANGE_COLLECTION_DAY = 'Change the collection day';
export const COLLECT_FROM_NEIGHBOUR = 'Collect from my neighbour';
export const CANCEL_COLLECTION = 'Cancel collection';
export const PROVIDE_CONTACT_INFO =
  'Provide your contact information and we will keep you updated on any changes to your collection';
export const COLLECT_FROM_SAFE_PLACE = 'Collect from safe place';
export const SAFE_PLACE_PLACEHOLDER = 'Side alley; Back garden etc';
export const DRIVER_INSTRUCTION_PLACEHOLDER = `Add any instructions that may\nhelp the driver\n\nmax 200 characters`;
export const SFP_DND_TEXT = `Please add a photo of the parcel to be\ncollected in your chosen safe place.\nThis will help your driver find your parcel*`;
export const CFN_DND_TEXT = `To help your driver find the\nproperty to collect your parcel\nfrom, please add a photo*`;
export const SOMETHING_WENT_WRONG = 'Oops, something went wrong';
export const PLEASE_CONFIRM_FOLLOWING = '* Please confirm the following:';
export const PAGE_NOT_FOUND = 'Page not found';
export const REQUEST_TIMEOUT = 'Request Timeout';
export const NOT_AUTHORISED = 'Not Authorised';
export const PROBLEM_COMPLETING_REQUEST =
  'Oops, there was a problem completing your request';
export const CONFLICT =
  'The request could not be completed due to a conflict with the current state of the target resource';
export const REQUEST_TIMEOUT_MESSAGE =
  'This request took longer than expected, please try again';
export const REQUEST_TIMEOUT_MESSAGE_TITLE =
  'This request took longer than expected';
export const NOT_AUTHORISED_MESSAGE =
  'Whoa there, you are not authorised to perform that request';
export const PAGE_NOT_FOUND_MESSAGE =
  'Oops, the page you are looking for has not been found';
export const TRY_AGAIN = 'CLICK HERE TO TRY AGAIN';
export const COLLECTION_HISTORY = 'Collection history';
export const DATE_AND_TIME = 'Date & Time';
export const LOCATION = 'Location';
export const DESCRIPTION = 'Description';
export const YOUR_COLLECTION_NUMBER = 'Your collection number';
export const ENTER_POSTCODE =
  'Enter your collection postcode to unlock live tracking & view your collection options';
export const REFERENCE_ENTRY_TITLE =
  'Enter the reference number and collection postcode';
export const COLLECTION_OPTIONS_TITLE = 'Not going to be in?';
export const CHOOSE_OPTION = 'Choose an option below';
export const UNAVAILABLE_OPTION = `This option isn't available on this collection`;
export const CONFIRM_CANCEL_COLLECTION = `Are you sure that you want to\n cancel this collection?`;
export const PARCEL_DRIVER_ARRIVE_TIME_$ =
  'We’ll be with you in approximately {0}';
export const REFERENCE_NUMBER_COULD_NOT_BE_FOUND =
  'Your reference number could not be found';
export const NEIGHBOUR_CONFIRM_MESSAGE =
  '* Please confirm that the parcel is adequately packaged with the delivery address clearly visible';
export const CHOOSE_NEIGHBOUR = 'Choose a neighbour for us to collect from';
export const SAFE_PLACE_CONFIRM_MESSAGE =
  'Your chosen safe place is secure and concealed\nThe parcel is adequately packaged with the delivery address clearly visible\nYou accept full responsibility for the parcel left';
export const OLD_BROWSER_ERROR =
  'The content on this page may not be displayed correctly due to your browser';
