import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapProps } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import AcceptFullResponsibility from '@dpdgroupuk/fmx-ui/components/AcceptFullResponsibility';
import SelectNeighbourList from '@dpdgroupuk/fmx-ui/components/SelectNeighbourList';
import HeadingWithAction from '@dpdgroupuk/fmx-ui/components/HeadingWithAction';
import { getDateString } from '@dpdgroupuk/fmx-ui/utils/string';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import {
  getAddressLine,
  sortAddresses,
} from '@dpdgroupuk/fmx-ui/utils/address';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

import { filterNeighborsAddresses } from './model';
import { fetchLocationsByPostcode } from '../../actions';
import { noop } from '../../../../utils/function';
import { CFN_STEPS } from '../../steps';
import type { Address } from '../../../../types';
import type { AuthorizedCollection } from '../../../../types/Collection';
import withFetch from '../../../../HOCs/withFetch';
import { WIZARD_CFN } from '../../../../constants/analytics';
import {
  SUBMIT,
  CHOOSE_NEIGHBOUR,
  NEIGHBOUR_CONFIRM_MESSAGE,
} from '../../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  confirmContainer: {
    [breakpoints.down('xs')]: {
      width: typography.pxToRem(280),
      marginTop: typography.pxToRem(25),
    },
    marginTop: typography.pxToRem(30),
    marginBottom: typography.pxToRem(20),
  },
  button: {
    marginBottom: typography.pxToRem(10),
  },
}));

type Props = {
  collection: AuthorizedCollection,
  data: Object,
  isSelectDayStepAvailable: boolean,
  goToStep: Function,
  addStepData: Function,
  fetchedData: Array<Address>,
};

const SelectNeighbourStep = ({
  collection,
  data,
  isSelectDayStepAvailable,
  addStepData,
  goToStep,
  fetchedData,
}: Props) => {
  const styles = useStyles();
  const { address, day } = data;
  const tracker = useTracker();
  const [isResponsibilityAccepted, acceptedResponsibility] = React.useState(
    false
  );
  const isSubmitButtonDisabled = !address || !isResponsibilityAccepted;

  const onSubmit = React.useCallback(() => {
    goToStep(CFN_STEPS.UPLOAD_PHOTO);
  }, [goToStep]);

  const onChangeDateClick = React.useCallback(() => {
    addStepData({ address: '' });
    goToStep(CFN_STEPS.SELECT_DAY);
  }, [addStepData, goToStep]);

  const onSelect = React.useCallback(address => addStepData({ address }), [
    addStepData,
  ]);

  const onTrackableSelect = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CFN.ON_SELECT);
      return onSelect(...args);
    },
    [onSelect, tracker]
  );

  const onTrackableSubmit = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CFN.ON_SUBMIT);
      return onSubmit(...args);
    },
    [onSubmit, tracker]
  );

  return (
    <>
      {isSelectDayStepAvailable && (
        <HeadingWithAction
          title={getDateString(day || collection.collectionDate)}
          buttonText="CHANGE"
          onClick={onChangeDateClick}
        />
      )}
      <SelectNeighbourList
        title={CHOOSE_NEIGHBOUR}
        addresses={fetchedData}
        onSelect={onTrackableSelect}
        getAddressLabel={getAddressLine}
        selectedAddress={address}
        onSubmit={onTrackableSubmit}
      />
      <AcceptFullResponsibility
        title={NEIGHBOUR_CONFIRM_MESSAGE}
        selected={isResponsibilityAccepted}
        onSelect={() => acceptedResponsibility(!isResponsibilityAccepted)}
        className={styles.confirmContainer}
      />
      <Button
        onClick={onTrackableSubmit}
        classes={{ root: styles.button }}
        style={{ visibility: isSubmitButtonDisabled ? 'hidden' : 'visible' }}
      >
        {SUBMIT}
      </Button>
    </>
  );
};

SelectNeighbourStep.defaultProps = {
  data: { address: null, day: '' },
  goToStep: noop,
  addStepData: noop,
};

export default compose(
  withRouter,
  withFetch(({ collection }) =>
    fetchLocationsByPostcode(collection.collectionDetails.address.postcode)
  ),
  mapProps(({ fetchedData, collection, ...props }) => ({
    fetchedData: sortAddresses(
      filterNeighborsAddresses(collection, fetchedData)
    ),
    collection,
    ...props,
  }))
)(SelectNeighbourStep);
