// @flow
import * as React from 'react';
import { compose } from 'redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { withCollection } from '../Collection/hocs';
import ChangeCollectionOption from '../ChangeCollectionOption';
import CollectionOptions from './CollectionOptions';
import { fetchCollectionActions } from './actions';
import withFetch from '../../HOCs/withFetch';

const CollectionOptionsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={CollectionOptions} />
      <Route component={ChangeCollectionOption} />
    </Switch>
  );
};

export default compose(
  withCollection,
  withFetch(fetchCollectionActions)
)(CollectionOptionsRouter);
