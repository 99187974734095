import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import ParcelInfo from '@dpdgroupuk/fmx-ui/components/ParcelInfo';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import TitleWithNumber from '@dpdgroupuk/fmx-ui/components/TitleWithNumber';

import {
  LITE_VIEW_TRACKING,
  trackable,
} from '../../../../../constants/analytics';
import TrackingPostcodeForm from './TrackingPostcodeForm';
import TrackingStatus from '../../../components/TrackingStatus';
import {
  trackAnotherCollectionHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  trackAnotherCollectionSpeedDeal,
  getDpdAppSpeedDeal,
  backToDpdSiteSpeedDeal,
} from '../../../../controlActions';
import type { Collection } from '../../../../../types/Collection';
import { MORE_COOL_STUFF } from '../../../../../constants/message';
import withFunctionalityLog from '../../../../../HOCs/withFunctionalityLog';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  container: {
    paddingBottom: typography.pxToRem(40),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(20),
      paddingRight: typography.pxToRem(20),
      paddingBottom: typography.pxToRem(35),
    },
  },
}));

type Props = {
  collection: Collection,
  onSubmit: Function,
};

const LightView = ({ collection, onSubmit }: Props) => {
  const styles = useStyles();
  const tracker = useTracker();
  const onFormTrackableSubmit = React.useCallback(
    (...args) => {
      tracker.logEvent(LITE_VIEW_TRACKING.SUBMIT);
      return onSubmit(...args);
    },
    [onSubmit, tracker]
  );
  useGoogleReCaptchaVisibleToggle();

  return (
    <Container maxWidth="md">
      <ParcelInfo
        logo={collection.customerImageLogo}
        contentWrapperClassName={styles.container}
      >
        <TitleWithNumber
          title={`Your collection number: ${collection.collectionNumber}`}
        />
        <TrackingStatus status={collection.collectionStatusFull} />
        <TrackingPostcodeForm onPostcodeSubmit={onFormTrackableSubmit} />
      </ParcelInfo>
    </Container>
  );
};

export const headerMenuActions = props => [
  trackAnotherCollectionHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

const speedDealActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  getDpdAppSpeedDeal(props),
  backToDpdSiteSpeedDeal(props),
];

export default compose(
  withRouter,
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(LITE_VIEW_TRACKING),
    headerMenuActions,
    speedDealActions,
  }),
  withFunctionalityLog('ky5F88pow0iRByurPoqU')
)(LightView);
