// @flow
import { Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Collection extends Model {
  // eslint-disable-next-line no-use-before-define
  static reducer(action: PayloadAction, Collection: Collection) {
    if (action.type === ACTIONS.FETCH_COLLECTION_BY_CODE.SUCCESS) {
      Collection.upsert({
        ...action.payload,
      });
    }
    if (action.type === ACTIONS.REMOVE_COLLECTION_BY_CODE) {
      const collection = Collection.withId(action.payload);
      collection.delete();
    }
  }

  static modelName = ENTITY_NAME.COLLECTION;

  static options = {
    idAttribute: 'collectionCode',
  };
}
