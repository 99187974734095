// @flow

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncAction } from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

type ActionTypes = {|
  FETCH_ACTIONS_BY_CODE: AsyncAction,
|};

export const ACTIONS = createActionTypes<ActionTypes>('ACTION', {
  FETCH_ACTIONS_BY_CODE: createAsyncActionTypes('FETCH_ACTIONS_BY_CODE'),
});

export const fetchCollectionActions = createAsyncAction(
  (collectionCode: string) =>
    fetchTimeoutHandler(apiService.getCollectionActions(collectionCode)).then(
      ({ data }) => ({ ...data, collectionCode })
    ),
  ACTIONS.FETCH_ACTIONS_BY_CODE
);
