import { useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';

import {
  fetchCacheableCollection,
  loginAndFetchCollection,
} from '../../store/actions/collections';
import { getCollectionData } from '../../store/orm/Collection/selectors';
import { ACTION } from '../../constants/recaptcha';
import { LITE_VIEW_MODAL } from '../../constants/analytics';
import { useLoginErrorModal } from '../hooks';

export const useCollectionData = () => {
  const dispatch = useDispatch();
  const { collectionId: collectionCode } = useParams();
  const collectionData = useSelector(state =>
    getCollectionData(state, collectionCode)
  );

  return useCallback(() => {
    if (typeof collectionCode !== 'string') {
      throw new Error('No collection code');
    }

    if (collectionData) {
      return Promise.resolve(collectionData);
    }

    return dispatch(fetchCacheableCollection(collectionCode));
  }, [dispatch, collectionCode, collectionData]);
};

export const usePostcodeSubmitCallback = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { collectionId = '' } = params;
  const showModal = useLoginErrorModal();
  const { executeRecaptchaAsync } = useGoogleReCaptcha();
  const store = useStore();

  return useCallback(
    async ([{ postcode = '' }]: {| postcode: string |}) => {
      const token = await executeRecaptchaAsync(ACTION.TRACKING);
      return dispatch(
        loginAndFetchCollection(collectionId, postcode, token)
      ).catch(error => {
        showModal(error, LITE_VIEW_MODAL);
        return getCollectionData(store.getState(), collectionId);
      });
    },
    [dispatch, collectionId, showModal, executeRecaptchaAsync, store]
  );
};
