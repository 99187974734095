import { formatPathName } from '../../../utils/path';
import { ROUTES } from '../constants';

export const getLocationReferenceEntry = () => ({
  pathname: ROUTES.REFERENCE_ENTRY,
});

export const getLocationTracking = params => ({
  pathname: formatPathName(ROUTES.TRACKING, params),
});

export const getLocationEvents = params => ({
  pathname: formatPathName(ROUTES.EVENTS, params),
});

export const getLocationDriverProfile = params => ({
  pathname: formatPathName(ROUTES.DRIVER_PROFILE, params),
});

export const getLocationCollectionOptions = params => ({
  pathname: formatPathName(ROUTES.COLLECTION_OPTIONS, params),
});

export const getLocationChangeCollection = params => ({
  pathname: formatPathName(ROUTES.CHANGE_COLLECTION, params),
});
