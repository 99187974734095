import * as DriverActions from '../../store/orm/Driver/actions';
import { getDriverData } from '../../store/orm/Driver/selectors';
import { createBulkLoadAsyncActions } from '../../utils/actionCreator';
import { RouteActions } from '../../store/orm/Route';
import { getRouteCode } from '../../models/collection';
import { getCollectionData } from '../../store/orm/Collection/selectors';
import { getRouteData } from '../../store/orm/Route/selectors';

export const fetchCacheableDriverRelatedData = createBulkLoadAsyncActions(
  [
    (state, { match }) => getDriverData(state, match.params.driverId),
    (state, { match }) => {
      const collection = getCollectionData(state, match.params.collectionId);

      return getRouteData(state, getRouteCode(collection));
    },
    () => null,
  ],
  [
    ({ match }) => dispatch =>
      dispatch(DriverActions.fetchDriver(match.params.driverId)),
    ({ match }) => (dispatch, getState) => {
      const collection = getCollectionData(
        getState(),
        match.params.collectionId
      );
      return dispatch(RouteActions.fetchRouteByCode(getRouteCode(collection)));
    },
    ({ match }) => dispatch =>
      dispatch(DriverActions.fetchDriverFeedback(match.params.driverId)),
  ]
);
