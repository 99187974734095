import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import type { AuthorizedCollection, Collection } from '../types/Collection';
import { getLocationTracking } from '../pages/Collection/actions/location';
import { withCollection } from '../pages/Collection/hocs';

type ProtectedRouteProps = {
  collection: Collection & AuthorizedCollection,
  path: string,
  component: any,
  publicComponent: any,
};

const ProtectedRoute = ({
  collection,
  path,
  component,
  publicComponent,
  ...props
}: ProtectedRouteProps) => {
  const isAuthorized = !!(collection && collection.collectionDetails);
  const destination = collection
    ? getLocationTracking({ collectionId: collection.collectionCode })
    : '/';
  const guestsComponent =
    publicComponent || (() => <Redirect to={destination} />);

  return (
    <Route
      path={path}
      component={isAuthorized ? component : guestsComponent}
      {...props}
    />
  );
};

export default compose(
  withRouter,
  withCollection
)(ProtectedRoute);
