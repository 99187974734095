// @flow
import { compose } from 'redux';
import { withProps } from 'recompose';

import { withCollection } from '../Collection/hocs';
import { goToTracking } from '../historyActions';
import ChangeCollectionRoutes from './routes';

export default compose(
  withCollection,
  withProps(props => ({
    goToTracking: () => goToTracking(props),
  }))
)(ChangeCollectionRoutes);
