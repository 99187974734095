import * as React from 'react';
import { withProps } from 'recompose';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import { CFN_STEPS } from '../../steps';
import { WIZARD_CHD } from '../../../../constants/analytics';

type Props = {
  selectedDate: string,
  dates: Array<string>,
  onSelect: Function,
  onSubmit: Function,
};

const SelectDayStep = ({ dates, selectedDate, onSelect, onSubmit }: Props) => {
  const tracker = useTracker();

  const onTrackableSelect = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CHD.ON_DAY_SELECT);
      return onSelect(...args);
    },
    [onSelect, tracker]
  );

  const onTrackableSubmit = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CHD.ON_SUBMIT);
      return onSubmit(...args);
    },
    [onSubmit, tracker]
  );

  return (
    <>
      <SelectDay
        title="Day of collection"
        subtitle="Select an upcoming day when you would like your parcel to be collected"
        selectedDate={selectedDate}
        onSelect={onTrackableSelect}
        dates={dates}
      />
      <SubmitButton
        onSubmit={onTrackableSubmit}
        isSubmitHidden={!selectedDate}
      />
    </>
  );
};

export default withProps(({ data, addStepData, goToStep, collectionDate }) => {
  const { day } = data;

  return {
    selectedDate: day || collectionDate,
    onSelect: day => {
      addStepData({ day });
    },
    onSubmit: () => goToStep(CFN_STEPS.SELECT_NEIGHBOUR),
  };
})(SelectDayStep);
