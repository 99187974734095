// @flow

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncAction } from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';

type ActionTypes = {|
  FETCH_ROUTE_BY_CODE: AsyncAction,
|};

export const ACTIONS = createActionTypes<ActionTypes>('ROUTE', {
  FETCH_ROUTE_BY_CODE: createAsyncActionTypes('FETCH_ROUTE_BY_CODE'),
});

export const fetchRouteByCode = createAsyncAction(
  (routeCode: string) =>
    fetchTimeoutHandler(apiService.getRouteByRouteCode(routeCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_ROUTE_BY_CODE
);
