import * as React from 'react';
import ENUM from '@dpdgroupuk/redback-enums';

import type {
  Collection,
  AuthorizedCollection,
} from '../../../../../types/Collection';
import OutForCollection from '../../OutForCollection';
import GeneralView from '../../GeneralView';
import CollectedView from '../../CollectedView';
import withFunctionalityLog from '../../../../../HOCs/withFunctionalityLog';

type Props = {
  collection: Collection & AuthorizedCollection,
};

const FullView = ({ collection }: Props) => {
  switch (collection.collectionStatusType) {
    case ENUM.COLLECTION_STATUS.OUT_FOR_COLLECTION:
      return <OutForCollection collection={collection} />;
    case ENUM.COLLECTION_STATUS.COLLECTED:
      return <CollectedView collection={collection} />;
    case ENUM.COLLECTION_STATUS.ISSUE:
    case ENUM.COLLECTION_STATUS.GENERAL:
    default:
      return <GeneralView collection={collection} />;
  }
};

export default withFunctionalityLog('IBJiVSdLd7AhG580R8uq')(FullView);
