import { RouteActions } from '../../../../store/orm/Route';
import { DriverActions } from '../../../../store/orm/Driver';
import { getRouteCode } from '../../../../models/collection';
import { getDriverId, isDriverAssigned } from '../../../../models/route';
import type { AuthorizedCollection } from '../../../../types/Collection';

export const fetchRouteAndDriver = (
  collection: AuthorizedCollection
) => async dispatch => {
  let driver = null;
  const route = await dispatch(
    RouteActions.fetchRouteByCode(getRouteCode(collection))
  );
  if (isDriverAssigned(route)) {
    driver = await dispatch(DriverActions.fetchDriver(getDriverId(route)));
  }

  return [route, driver];
};
