// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@dpdgroupuk/fmx-ui/components/Button';

const useStyles = makeStyles(({ typography }) => ({
  root: {
    boxShadow: 'none',
    width: typography.pxToRem(105),
    color: '#1d9ad0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

type Props = {
  buttonText?: string,
  className?: Object,
  onClick: any => any,
};

const SecondaryButton = ({
  onClick = () => null,
  buttonText = 'SKIP',
  className,
}: Props) => {
  const styles = useStyles();

  return (
    <Button
      classes={{ root: styles.root }}
      className={className}
      onClick={onClick}
      variant="text"
    >
      {buttonText}
    </Button>
  );
};

export default SecondaryButton;
