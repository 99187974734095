import { StatusCodes } from 'http-status-codes';
import {
  CONFLICT,
  NOT_AUTHORISED_MESSAGE,
  PAGE_NOT_FOUND_MESSAGE,
  PROBLEM_COMPLETING_REQUEST,
  REQUEST_TIMEOUT_MESSAGE,
} from '../constants/message';

export const getStatusMessage = status => {
  switch (parseInt(status)) {
    case StatusCodes.UNAUTHORIZED:
    case StatusCodes.FORBIDDEN:
      return NOT_AUTHORISED_MESSAGE;
    case StatusCodes.NOT_FOUND:
      return PAGE_NOT_FOUND_MESSAGE;
    case StatusCodes.REQUEST_TIMEOUT:
      return REQUEST_TIMEOUT_MESSAGE;
    case StatusCodes.CONFLICT:
      return CONFLICT;
    default: {
      return PROBLEM_COMPLETING_REQUEST;
    }
  }
};
