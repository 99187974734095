// @flow

import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Driver extends Model {
  static reducer(action: PayloadAction, Driver: any) {
    if (
      action.type === ACTIONS.FETCH_DRIVER.SUCCESS ||
      action.type === ACTIONS.FETCH_DRIVER_FEEDBACK.SUCCESS
    ) {
      const driver = { ...action.payload };

      Driver.upsert(driver);
    }
  }

  static modelName = ENTITY_NAME.DRIVER;

  static fields = {
    driverCode: attr(),
  };

  static options = {
    idAttribute: 'driverCode',
  };
}
