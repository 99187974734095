import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import ErrorScreen from '@dpdgroupuk/fmx-ui/components/ErrorScreen';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import {
  HOME,
  NOT_AUTHORISED,
  NOT_AUTHORISED_MESSAGE,
} from '../../constants/message';
import { goToReferenceEntry } from '../historyActions';
import { ERROR_SCREEN, trackable } from '../../constants/analytics';

export default compose(
  withRouter,
  withPageControl({
    title: NOT_AUTHORISED,
    backgroundImage: IMAGES.TRACKING,
    trackable: trackable(ERROR_SCREEN),
  }),
  withProps(props => ({
    title: NOT_AUTHORISED_MESSAGE,
    buttonText: HOME,
    onClick: () => goToReferenceEntry(props.history),
  }))
)(ErrorScreen);
