import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';
import { withOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';
import * as ENUM from '@dpdgroupuk/redback-enums';

import sentry from '../../services/sentry';
import { ACTION } from '../../constants/recaptcha';
import {
  HOME,
  CLOSE,
  NOT_AUTHORISED_MESSAGE,
  REQUEST_TIMEOUT_MESSAGE,
  PROBLEM_COMPLETING_REQUEST,
} from '../../constants/message.js';
import { withCollection } from '../Collection/hocs';
import { fetchCollection } from '../../store/actions/collections';
import { removeCollectionByCode } from '../../store/orm/Collection/actions';
import { goToReferenceEntry } from '../historyActions';

type Props = {
  actionCode: string,
  dates: Function,
};

const handleUpdateRequestError = ({ error, modal, history }) => {
  switch (error.errorCode) {
    case 401:
      return modal.showModal({
        title: NOT_AUTHORISED_MESSAGE,
        description: null,
        buttonText: HOME,
        onConfirm: () => {
          modal.hideModal();
          goToReferenceEntry(history);
        },
      });
    case 408:
      return modal.showModal({
        title: REQUEST_TIMEOUT_MESSAGE,
        buttonText: CLOSE,
        description: null,
      });
    default: {
      sentry.captureException(error);
      modal.showModal({
        title: PROBLEM_COMPLETING_REQUEST,
        buttonText: CLOSE,
        description: null,
      });
    }
  }
};

export const withCollectionUpdate = ({
  actionCode,
  changeCollectionAction,
}): Props =>
  compose(
    withRouter,
    withCollection,
    withModal,
    withOverlay,
    withGoogleReCaptcha,
    connect(
      null,
      (dispatch, { overlay, reCaptchaConfig, collection, modal, history }) => ({
        onSubmitFailure: error =>
          handleUpdateRequestError({ error, modal, history }),
        updateCollection: overlay.showWhile(async (data, query = {}) => {
          const token = await reCaptchaConfig.executeRecaptchaAsync(
            ACTION.CHANGE_COLLECTION_OPTION
          );

          const {
            collectionCode: newCollectionCode,
          } = await changeCollectionAction(collection, data, {
            token,
            actionCode,
            ...query,
          });

          if (actionCode === ENUM.COLLECTION_ACTION_CODE.CAN) {
            // do not perform any updates for canceled collection
            history.push(
              `/collections/${collection.collectionCode}/completed`,
              { collection, actionCode }
            );
            return false;
          }

          await dispatch(
            handleCollectionCodeChange(
              newCollectionCode,
              actionCode,
              collection,
              data,
              history
            )
          );
        }),
      })
    )
  );

export const handleCollectionCodeChange = (
  newCollectionCode,
  actionCode,
  collection,
  data,
  history
) => dispatch => {
  dispatch(fetchCollection(newCollectionCode))
    .then(dispatch(removeCollectionByCode(collection.collectionCode)))
    .then(history.replace(`/collections/${newCollectionCode}/options`))
    .finally(
      history.push(`/collections/${newCollectionCode}/completed`, {
        data,
        actionCode,
        collection: { ...collection, collectionCode: newCollectionCode },
      })
    );
};
