// @flow
import { noop } from './function';

type NewPromise = {|
  promise: Promise<void>,
  resolve: any => void,
|};
export const noopPromise = () => Promise.resolve();

export const postpone = (delay = 1000) =>
  new Promise(resolve => {
    setTimeout(resolve, delay);
  });

export const createNewPromise = (): NewPromise => {
  let resolveLocal = noop;
  const promise = new Promise<void>(resolve => {
    resolveLocal = resolve;
  });

  return {
    promise,
    resolve: resolveLocal,
  };
};
