import React from 'react';
import { compose } from 'redux';
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import Page from '@dpdgroupuk/fmx-ui/components/Page';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import GoogleReCaptchaProvider from '@dpdgroupuk/react-google-recaptcha-v3';
import { scrollToTop } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { withSnackbar } from 'notistack';
import { lifecycle } from 'recompose';
import { isOldBrowser } from '@dpdgroupuk/fmx-ui/utils/getBrowserVersion';

import { ReferenceEntry } from './pages/ReferenceEntry';
import ErrorPage from './pages/Error';
import Collection from './pages/Collection';
import * as ROUTES from './constants/routes';
import { FOOTER, HEADER } from './constants/analytics';
import { OLD_BROWSER_ERROR } from './constants/message';

export const REACT_APP_RECAPTURE_SITE_KEY: string =
  process.env.REACT_APP_RECAPTURE_SITE_KEY || '';

const Routes = () => {
  const tracker = useTracker();
  const { pathname } = useLocation();
  React.useEffect(scrollToTop, [pathname]);

  const onSwitchTheme = React.useCallback(
    themeName => {
      tracker.logEvent({
        actionId: HEADER.TOGGLE_DARK,
        themeName,
      });
    },
    [tracker]
  );

  tracker.setContext({
    userAgent: navigator.userAgent,
  });

  return (
    <Page analytics={FOOTER} pathname={pathname} onSwitchTheme={onSwitchTheme}>
      <Switch>
        <Route exact path={ROUTES.REFERENCE_ENTRY} component={ReferenceEntry} />
        <Route path={ROUTES.SEARCH} component={ReferenceEntry} />
        <Route path={ROUTES.COLLECTION} component={Collection} />
        <Route path={ROUTES.ERROR} component={ErrorPage} />
        <Redirect
          to={{
            pathname: '/error',
            search: '?status=404',
          }}
        />
      </Switch>
    </Page>
  );
};

const RoutesWrapper = () => {
  const theme = useTheme();

  return (
    <Grid container direction="column" alignContent="stretch" wrap="nowrap">
      <GoogleReCaptchaProvider
        sitekey={REACT_APP_RECAPTURE_SITE_KEY}
        visibility="hidden"
        theme={theme.palette.type}
        version="v2"
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </Grid>
  );
};

export default compose(
  withSnackbar,
  lifecycle({
    async componentDidMount() {
      if (isOldBrowser()) {
        this.props.enqueueSnackbar(OLD_BROWSER_ERROR, {
          variant: 'error',
          autoHideDuration: 20000,
        });
      }
    },
  })
)(RoutesWrapper);
