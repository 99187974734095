import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';
import { OFxDesktop, OFxMobile } from '@dpdgroupuk/fmx-ui/components/OFx';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';

import {
  OUT_FOR_DELIVERY,
  trackable,
} from '../../../../../constants/analytics';
import { getOFCData } from '../model';
import type { AuthorizedCollection } from '../../../../../types/Collection';
import {
  learnMoreAboutDriverHeader,
  trackAnotherCollectionHeader,
  collectionDetailsHeader,
  changeCollectionHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  learnMoreAboutDriverSpeedDeal,
  trackAnotherCollectionSpeedDeal,
  collectionDetailsSpeedDeal,
  changeCollectionSpeedDeal,
} from '../../../../controlActions';
import { fetchRouteAndDriver } from '../actions';
import withFetchCollectionDetails from '../../../../../HOCs/withFetchCollectionDetails';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

type Props = {
  collection: AuthorizedCollection,
  driver: any,
};

const headerMenuActions = props =>
  [
    trackAnotherCollectionHeader(props),
    props.driver && learnMoreAboutDriverHeader(props),
    collectionDetailsHeader(props),
    changeCollectionHeader(props),
    getDpdAppHeader(props),
    backToDpdSiteHeader(props),
  ].filter(action => !!action);

const speedDealActions = props => [
  props.driver
    ? learnMoreAboutDriverSpeedDeal(props)
    : trackAnotherCollectionSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
  changeCollectionSpeedDeal(props),
];

export default compose(
  withRouter,
  withFetchCollectionDetails({
    fetch: fetchRouteAndDriver,
    dataPropMapper: ([route, driver]) => ({
      route,
      driver,
    }),
  }),
  withProps(({ collection, driver }: Props) => getOFCData(collection, driver)),
  withPageControl({
    trackable: trackable(OUT_FOR_DELIVERY),
    isFooterHidden: true,
    headerMenuActions,
    speedDealActions,
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
  })
)(isMobile ? OFxMobile : OFxDesktop);
