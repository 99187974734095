import {
  getLocationTracking,
  getLocationReferenceEntry,
  getLocationChangeCollection,
} from './Collection/actions/location';
import * as ROUTES from '../constants/routes';

export const goToReferenceEntry = history => {
  history.replace(getLocationReferenceEntry());
};

export const trackAnotherCollection = ({ history }) => {
  history.push(ROUTES.REFERENCE_ENTRY);
};

export const goToTracking = ({ history, collection }) => {
  history.push(
    getLocationTracking({ collectionId: collection.collectionCode })
  );
};

export const goToChangeCollection = ({ history, collection, actionCode }) => {
  history.push(
    getLocationChangeCollection({
      collectionId: collection.collectionCode,
      actionCode,
    })
  );
};
