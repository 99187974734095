// @flow
import type { Action } from '@dpdgroupuk/fmx-ui/types';

import api from './api';

export const getCollectionActions = (collectionCode: string) => {
  return api.get<_, Action>({
    path: `/collections/${collectionCode}/actions`,
  });
};

export const getActionsDates = (collectionCode: string, actionCode: string) => {
  return api.get<_, Array<string>>({
    path: `/collections/${collectionCode}/actions/${actionCode}`,
  });
};

export type ChangeCollectionOptionOptions = {
  token: string,
  actionCode: string,
  createProfileLink: boolean,
};

export const changeCollectionOption = (
  collectionCode: string,
  body: any,
  { token, actionCode, createProfileLink }: ChangeCollectionOptionOptions
): Promise<any> => {
  return api.post<_, any, any>({
    path: `/collections/${collectionCode}/actions/${actionCode}`,
    query: { createProfileLink },
    headers: {
      'g-recaptcha-response': token,
    },
    body,
  });
};
