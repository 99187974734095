import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCollectionActionsByDepotCode } from '../../store/orm/Action/selectors';

let ProtectedCollectionOptionRoute = ({
  actionCode,
  collectionActions,
  match,
  ...props
}: any) => {
  if (collectionActions.availableActions.includes(actionCode)) {
    return <Route {...props} match={match} />;
  } else {
    return <Redirect to={match.url} />;
  }
};

ProtectedCollectionOptionRoute = compose(
  withRouter,
  connect((state, { match }) => {
    const collectionId = match.params.collectionId;

    return {
      collectionActions: getCollectionActionsByDepotCode(state, collectionId),
    };
  })
)(ProtectedCollectionOptionRoute);

export default ProtectedCollectionOptionRoute;
