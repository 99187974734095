import React from 'react';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import * as M from '../constants/message';

export const useLoginErrorModal = () => {
  const { showModal } = useModal();

  return React.useCallback(
    (error, analytics) =>
      showModal({
        title:
          error.statusCode < 500
            ? M.REFERENCE_NUMBER_COULD_NOT_BE_FOUND
            : M.SOMETHING_WENT_WRONG,
        description: 'Please check your details and try again.',
        analytics,
      }),
    [showModal]
  );
};
