import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { THEME_EVENT } from '@dpdgroupuk/redback-enums';
import ThemeProvider from '@dpdgroupuk/fmx-ui/components/Theme';

const ThemeAndEventProvider = ({ children }: any) => {
  const theme = createMuiTheme({ eventName: THEME_EVENT.NONE });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeAndEventProvider;
