// @flow
import { get } from 'lodash';
import type { Driver, Route } from '@dpdgroupuk/fmx-ui/types';
import { formatMessage } from '@dpdgroupuk/fmx-ui/utils/helpers';
import {
  chooseSmallerDate,
  formatInternalTime,
  formatTime,
  getCurrentDateTime,
  calculatePositionInPeriod,
  getHoursMinutes,
} from '@dpdgroupuk/fmx-ui/utils/date';

import type { AuthorizedCollection } from '../../../../types/Collection';
import { isElectric } from '../../../../models/driver';
import {
  YOUR_COLLECTION_NUMBER,
  PARCEL_DRIVER_ARRIVE_TIME_$,
} from '../../../../constants/message';

const getRouteStatus = (stop, driverName) => {
  const currentStop =
    stop.completedCollectionStops + stop.completedDeliveryStops;

  const myStop = stop.stopNumber || 0;

  if (myStop && currentStop >= myStop) {
    return `Total Stops Completed so far = ${myStop - 1}`;
  }

  if (currentStop) {
    return `${driverName ||
      'Driver'} is currently making stop number ${currentStop}, this collection is stop number ${myStop}`;
  }
  return null;
};

export const getDriverArriveTimeText = (route: Route) => {
  const estimatedMinsToStop = get(route, 'stop.estimatedMinsToStop', 0);

  if (!estimatedMinsToStop) {
    return null;
  }

  const estimatedMinsToStopFormatted = getHoursMinutes(estimatedMinsToStop);

  return formatMessage(
    PARCEL_DRIVER_ARRIVE_TIME_$,
    estimatedMinsToStopFormatted
  );
};

export const getOFCData = (
  collection: AuthorizedCollection,
  driver: Driver = {
    vehicleTypeName: 'vehicle',
    vehicleTypeCode: '',
  }
) => {
  const route = get(collection, 'collectionDetails.route', {});
  const stop = get(route, 'stop', {});
  const collectionWindowFrom = stop.collectionWindowFrom || '';
  const collectionWindowTo = stop.collectionWindowTo || '';
  const driverName = route.driverDisplayName || get(driver, 'driverName', '');

  const routeTimeInSeconds = route.routeTime;
  const routeDateTime = routeTimeInSeconds
    ? formatInternalTime(routeTimeInSeconds)
    : null;

  const driverStartTime = chooseSmallerDate(
    routeDateTime,
    collectionWindowFrom
  );

  let currentPosition = calculatePositionInPeriod(
    getCurrentDateTime(),
    driverStartTime,
    collectionWindowTo
  );
  // add min value as 5 to avoid negative values in progress
  currentPosition = Math.max(currentPosition, 5);

  const startPosition = calculatePositionInPeriod(
    collectionWindowFrom,
    driverStartTime,
    collectionWindowTo
  );

  const progressData = {
    showProgressBar: currentPosition < 100,
    progressStatus: getRouteStatus(stop, driverName),
    currentPosition,
    startPosition,
    windowStartTime: formatTime(collectionWindowFrom),
    windowEndTime: formatTime(collectionWindowTo),
    vehicleTypeCode: get(driver, 'vehicleTypeCode', ''),
  };

  return {
    code: collection.collectionCode,
    customerImageLogo: collection.customerImageLogo,
    deliveryNumber: `${YOUR_COLLECTION_NUMBER}: ${collection.collectionNumber}`,
    statusHtml: collection.collectionStatusFull,
    query: 'collectionCode',
    electricMessage: `Proudly\ncollecting in a 100%\nelectric ${driver.vehicleTypeName}`,
    isVehicleElectric: isElectric(driver),
    progressData,
    additionalInfo: getDriverArriveTimeText(route),
  };
};
