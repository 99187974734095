import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withCollection } from './hocs';
import CollectionRoutes from './routes';
import { fetchCacheableCollection } from '../../store/actions/collections';
import withFetch from '../../HOCs/withFetch';

export default compose(
  withRouter,
  withFetch(fetchCacheableCollection),
  withCollection
)(CollectionRoutes);
