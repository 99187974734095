import { changeCollectionOption } from '../../../../store/orm/Collection/actions';
import { getNotificationDetails } from '../../../../models/collection';
import type { AuthorizedCollection } from '../../../../types/Collection';
import {
  calculateChangeCollectionDate,
  calculateInstruction,
} from '../../model';

export const collectFromSafePlace = (
  collection: AuthorizedCollection,
  { safePlace, driverInstructions, day, notificationDetails, imageKey },
  options
) => {
  const instructions = calculateInstruction(safePlace, driverInstructions);
  const data = {
    collectionDate: calculateChangeCollectionDate(
      day,
      collection.collectionDate
    ),
    safePlaceCode: safePlace.id,
    ...(imageKey && { imageKey }),
    ...(instructions && { instructions }),
    notificationDetails:
      notificationDetails || getNotificationDetails(collection),
  };
  return changeCollectionOption(collection.collectionCode, data, options);
};
