// @flow

import * as React from 'react';
import { isEmpty } from 'lodash';
import { getDateString } from '@dpdgroupuk/fmx-ui/utils/string';
import HeadingWithAction from '@dpdgroupuk/fmx-ui/components/HeadingWithAction';

import type { AuthorizedCollection } from '../../../types/Collection';

type Props = {
  onDateChange: Function,
  collection: AuthorizedCollection,
  day: string,
  dates: Array<string>,
};

export default ({ onDateChange, day, collection, dates }: Props) =>
  !isEmpty(dates) && (
    <HeadingWithAction
      title={getDateString(day || collection.collectionDate)}
      buttonText="CHANGE"
      onClick={onDateChange}
    />
  );
