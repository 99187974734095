// @flow

import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import { SAFE_PLACE_TYPE, SAFE_PLACE_NAME } from '@dpdgroupuk/redback-enums';
import Input from '@dpdgroupuk/fmx-ui/components/Input';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { createSafePlace } from '@dpdgroupuk/fmx-ui/components/SafePlaceItem';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import { DRIVER_INSTRUCTION_PLACEHOLDER } from '../../../../constants/message';
import { SFP_STEPS } from '../../steps';
import type { AuthorizedCollection } from '../../../../types/Collection';
import ChangeSafePlaces from '../../components/ChangeSafePlaces';
import ChangeDate from '../../components/ChangeDate';
import SecondaryButton from '../../../../components/SecondaryButton';
import { WIZARD_COMMON } from '../../../../constants/analytics';

const useStyles = makeStyles(({ breakpoints, typography, palette }) => ({
  input: {
    [breakpoints.down('sm')]: {
      width: 240,
      marginTop: typography.pxToRem(10),
      marginBottom: typography.pxToRem(20),
    },
    width: 260,
    height: 180,
    marginTop: 12,
    marginBottom: typography.pxToRem(30),
    border: 'solid 1px rgba(255, 255, 255, 0.44)',
    '& div': {
      padding: 0,
      height: '100%',
      width: '100%',
    },
    '& textarea': {
      paddingTop: typography.pxToRem(6),
      paddingLeft: typography.pxToRem(10),
      paddingRight: typography.pxToRem(10),
      fontSize: typography.pxToRem(13),
      fontWeight: 300,
      lineHeight: 1.25,
      color: palette.text.primary,
      '&::placeholder': {
        color: 'solid 1px rgba(255, 255, 255, 0.44)',
      },
    },
  },
  button: {
    marginBottom: typography.pxToRem(40),
  },
}));

type Props = {
  collection: AuthorizedCollection,
  data: Object,
  dates: Array<string>,
  onSubmit: Function,
  onInstructionChange: Function,
  onInstructionSkip: Function,
  onDateChange: Function,
  onSafePlaceChange: Function,
};

const DescribeSafePlaceStep = ({
  collection,
  data,
  dates,
  onSubmit,
  onInstructionChange,
  onInstructionSkip,
  onDateChange,
  onSafePlaceChange,
}: Props) => {
  const styles = useStyles();
  const { driverInstructions } = data;
  const isDriverInstructionsValid =
    driverInstructions && /(.|\s)*\S(.|\s)*/.test(driverInstructions);

  return (
    <>
      <ChangeDate
        day={dates && dates[0]}
        dates={dates}
        collection={collection}
        onDateChange={onDateChange}
      />
      <ChangeSafePlaces data={data} onSafePlaceChange={onSafePlaceChange} />
      <Input
        value={driverInstructions}
        className={styles.input}
        onChange={onInstructionChange}
        name={SAFE_PLACE_NAME.OTH}
        placeholder={DRIVER_INSTRUCTION_PLACEHOLDER}
        inputProps={{ maxLength: 200 }}
        multiline
        rows="10"
      />
      <SecondaryButton onClick={onInstructionSkip} />
      <br />
      <Button
        onClick={onSubmit}
        classes={{ root: styles.button }}
        style={{ visibility: isDriverInstructionsValid ? 'visible' : 'hidden' }}
      >
        SUBMIT
      </Button>
    </>
  );
};

DescribeSafePlaceStep.defaultProps = {
  data: { day: '' },
  safePlaces: [
    createSafePlace(SAFE_PLACE_TYPE.FPC),
    createSafePlace(SAFE_PLACE_TYPE.GAR),
    createSafePlace(SAFE_PLACE_TYPE.OBL),
    createSafePlace(SAFE_PLACE_TYPE.RPC),
  ],
};

export default compose(
  withProps(({ goToStep, addStepData }) => {
    const onSubmit = () => goToStep(SFP_STEPS.UPLOAD_SAFE_PLACE_PHOTO);

    return {
      onSubmit,
      onInstructionChange: event =>
        addStepData({
          driverInstructions: event.target.value,
        }),
      onInstructionSkip: () => {
        addStepData({
          driverInstructions: '',
        });
        onSubmit();
      },
      onDateChange: () => goToStep(SFP_STEPS.SELECT_DAY),
      onSafePlaceChange: () => goToStep(SFP_STEPS.SELECT_SAFE_PLACE),
    };
  }),
  withTrackProps({
    onDateChange: WIZARD_COMMON.ON_DATE_CHANGE,
  })
)(DescribeSafePlaceStep);
