import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import ParcelInfo from '@dpdgroupuk/fmx-ui/components/ParcelInfo';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import TitleWithNumber from '@dpdgroupuk/fmx-ui/components/TitleWithNumber';

import { GENERAL_VIEW, trackable } from '../../../../../constants/analytics';
import TrackingStatus from '../../../components/TrackingStatus';
import {
  trackAnotherCollectionHeader,
  collectionDetailsHeader,
  changeCollectionHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  trackAnotherCollectionSpeedDeal,
  collectionDetailsSpeedDeal,
  changeCollectionSpeedDeal,
} from '../../../../controlActions';
import type {
  Collection,
  AuthorizedCollection,
} from '../../../../../types/Collection';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

type Props = {
  collection: Collection & AuthorizedCollection,
};

const GeneralView = ({ collection }: Props) => {
  return (
    <FluidContainer>
      <ParcelInfo logo={collection.customerImageLogo}>
        <TitleWithNumber
          title={`Your collection number: ${collection.collectionNumber}`}
        />
        <TrackingStatus status={collection.collectionStatusFull} />
      </ParcelInfo>
    </FluidContainer>
  );
};

const headerMenuActions = props => [
  trackAnotherCollectionHeader(props),
  collectionDetailsHeader(props),
  changeCollectionHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

const speedDealActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
  changeCollectionSpeedDeal(props),
];

export default compose(
  withRouter,
  withPageControl({
    trackable: trackable(GENERAL_VIEW),
    headerMenuActions,
    speedDealActions,
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
  })
)(GeneralView);
