import * as React from 'react';
import { withProps } from 'recompose';
import SelectDay from '@dpdgroupuk/fmx-ui/components/DayPicker';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { SFP_STEPS } from '../../steps';
import { WIZARD_CHD } from '../../../../constants/analytics';

type Props = {
  selectedDate: string,
  dates: Array<string>,
  onSelect: Function,
  onSubmit: Function,
};

const SelectDayStep = ({ dates, selectedDate, onSelect, onSubmit }: Props) => {
  const tracker = useTracker();

  const onTrackableSelect = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CHD.ON_DAY_SELECT);
      return onSelect(...args);
    },
    [onSelect, tracker]
  );

  const onTrackableSubmit = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_CHD.ON_SUBMIT);
      return onSubmit(...args);
    },
    [onSubmit, tracker]
  );

  return (
    <>
      <SelectDay
        title="Day of collection"
        subtitle="Select an upcoming day when you would like your parcel to be collected"
        selectedDate={selectedDate}
        onSelect={onTrackableSelect}
        dates={dates}
      />
      <Button onClick={onTrackableSubmit}>Submit</Button>
    </>
  );
};

export default withProps(({ addStepData, data, collectionDate, goToStep }) => ({
  onSelect: day => {
    addStepData({ day });
  },
  onSubmit: () => {
    goToStep(SFP_STEPS.SELECT_SAFE_PLACE);
  },
  selectedDate: data.day || collectionDate,
}))(SelectDayStep);
