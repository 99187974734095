import type { AuthorizedParcel, Address } from '../../../../types';

export const filterNeighborsAddresses = (
  collection: AuthorizedParcel,
  addresses: Array<Address>
) => {
  const udprn = collection.collectionDetails.address.udprn;

  return udprn
    ? addresses.filter((address: Address) => address.udprn !== udprn)
    : addresses;
};
