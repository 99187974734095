// @flow
import * as React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import BackToTrackingMap from '@dpdgroupuk/fmx-ui/components/BackToTrackingMap';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import { getLocationCollectionOptions } from '../Collection/actions/location';
import ChangeCollectionDate from './pages/ChangeCollectionDate';
import CollectFromSafePlace from './pages/CollectFromSafePlace';
import CollectFromNeighbour from './pages/CollectFromNeighbour';
import CancelCollection from './pages/CancelСollection';
import ProtectedCollectionOptionRoute from './ProtectedCollectionOptionRoute';
import type { Collection, AuthorizedCollection } from '../../types/Collection';
import { WIZARD_COMMON } from '../../constants/analytics';

type Props = {
  collection: Collection & AuthorizedCollection,
  goToTracking: Function,
};

const Routes = ({ collection, goToTracking }: Props) => {
  const tracker = useTracker();
  const { path } = useRouteMatch();

  const onTrackableGoToTracking = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_COMMON.BACK_TO_TRACKING);
      return goToTracking(...args);
    },
    [goToTracking, tracker]
  );

  return (
    <BackToTrackingMap onClick={onTrackableGoToTracking}>
      <Switch>
        <ProtectedCollectionOptionRoute
          path={`${path}/${ENUM.COLLECTION_ACTION_CODE.CHD}`}
          actionCode={ENUM.COLLECTION_ACTION_CODE.CHD}
          component={ChangeCollectionDate}
        />
        <ProtectedCollectionOptionRoute
          path={`${path}/${ENUM.COLLECTION_ACTION_CODE.CFN}`}
          actionCode={ENUM.COLLECTION_ACTION_CODE.CFN}
          component={CollectFromNeighbour}
        />
        <ProtectedCollectionOptionRoute
          path={`${path}/${ENUM.COLLECTION_ACTION_CODE.CAN}`}
          actionCode={ENUM.COLLECTION_ACTION_CODE.CAN}
          component={CancelCollection}
        />
        <ProtectedCollectionOptionRoute
          path={`${path}/${ENUM.COLLECTION_ACTION_CODE.SFP}`}
          actionCode={ENUM.COLLECTION_ACTION_CODE.SFP}
          component={CollectFromSafePlace}
        />
        <Route
          children={() => (
            <Redirect
              to={getLocationCollectionOptions({
                collectionId: collection.collectionCode,
              })}
            />
          )}
        />
      </Switch>
    </BackToTrackingMap>
  );
};

export default Routes;
