// @flow

import type { Route } from '@dpdgroupuk/fmx-ui/types';

import api from './api';

export const getRouteByRouteCode = (routeCode: string) => {
  return api.get<_, Route>({
    path: `/routes/${routeCode}`,
  });
};
