import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { StatusCodes } from 'http-status-codes';
import Container from '@material-ui/core/Container';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import queryString from 'query-string';
import { withTracker, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { lifecycle, compose } from 'recompose';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';
import { withGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';

import ReferenceEntryForm from './ReferenceEntryForm';
import { REFERENCE_ENTRY, trackable } from '../../../constants/analytics';
import { getDpdAppHeader, backToDpdSiteHeader } from '../../controlActions';
import { MORE_COOL_STUFF } from '../../../constants/message';
import { withSearchSubmitHandler } from './hocs';
import { postpone } from '../../../utils/promise';

type Props = {
  location: Object,
  onSearchSubmit: Function,
};

const ReferenceEntryComponent = ({ location, onSearchSubmit }: Props) => {
  useGoogleReCaptchaVisibleToggle();

  const initialValues = React.useMemo(() => {
    const query = queryString.parse(location.search);
    return {
      referenceNumber: query.reference,
      postcode: query.postcode,
    };
  }, [location]);

  return (
    <Container maxWidth="md">
      <ReferenceEntryForm
        initialValues={initialValues}
        onReferenceSubmit={onSearchSubmit}
      />
    </Container>
  );
};

const headerMenuActions = props => [
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

export default compose(
  withRouter,
  withModal,
  withOverlay,
  withTracker,
  withGoogleReCaptcha,
  withSearchSubmitHandler,
  withTrackProps({
    onSearchSubmit: REFERENCE_ENTRY.SEARCH,
  }),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(REFERENCE_ENTRY),
    headerMenuActions,
  }),
  lifecycle({
    async componentDidMount() {
      const { location, onSearchSubmitFailure } = this.props;
      const query = queryString.parse(location.search);
      if (query.reference) {
        await postpone();
        onSearchSubmitFailure({
          statusCode: StatusCodes.NOT_FOUND,
        });
      }
    },
  })
)(ReferenceEntryComponent);
