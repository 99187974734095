// @flow

export const formatReferenceNumberForServer = (value: string) =>
  value.replace(/ /g, '');

export const formatPostcodeForServer = (value: string = '') =>
  value.toUpperCase();

export const normalizeReferenceNumber = (val: string) => {
  const refNumber = formatReferenceNumberForServer(val);
  if (refNumber.length > 4) {
    return `${refNumber.slice(0, 4)} ${normalizeReferenceNumber(
      refNumber.slice(4, 28)
    )}`;
  }
  return refNumber;
};

export const parseRefNumberAndPostcode = (values: Object) => {
  if (
    values.referenceNumber &&
    !values.postcode &&
    values.referenceNumber.indexOf('*') > -1
  ) {
    const [postcode, referenceNumber] = values.referenceNumber.split('*');
    return {
      ...values,
      postcode,
      referenceNumber,
    };
  }
  return values;
};
