import { compose } from 'redux';
import { withProps } from 'recompose';
import ErrorScreen from '@dpdgroupuk/fmx-ui/components/ErrorScreen';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import {
  TRY_AGAIN,
  REQUEST_TIMEOUT,
  REQUEST_TIMEOUT_MESSAGE,
} from '../../constants/message';
import { ERROR_SCREEN, trackable } from '../../constants/analytics';

export default compose(
  withPageControl({
    title: REQUEST_TIMEOUT,
    backgroundImage: IMAGES.TRACKING,
    trackable: trackable(ERROR_SCREEN),
  }),
  withProps(() => ({
    title: REQUEST_TIMEOUT_MESSAGE,
    buttonText: TRY_AGAIN,
    onClick: () => window.location.reload(),
  }))
)(ErrorScreen);
