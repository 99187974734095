import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import ParcelInfo from '@dpdgroupuk/fmx-ui/components/ParcelInfo';
import DeliveryImages from '@dpdgroupuk/fmx-ui/components/DeliveryImages';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import TitleWithNumber from '@dpdgroupuk/fmx-ui/components/TitleWithNumber';

import withFetchCollectionDetails from '../../../../../HOCs/withFetchCollectionDetails';
import { COLLECTED_VIEW, trackable } from '../../../../../constants/analytics';
import { withCollection } from '../../../../Collection/hocs';
import { getRouteCode } from '../../../../../models/collection';
import { isDriverAssigned } from '../../../../../models/route';
import { RouteActions } from '../../../../../store/orm/Route';
import TrackingStatus from '../../../components/TrackingStatus';
import {
  trackAnotherCollectionHeader,
  collectionDetailsHeader,
  learnMoreAboutDriverHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  trackAnotherCollectionSpeedDeal,
  collectionDetailsSpeedDeal,
  backToDpdSiteSpeedDeal,
} from '../../../../controlActions';
import type {
  Collection,
  AuthorizedCollection,
} from '../../../../../types/Collection';
import { getImagesData } from '../model';
import { MORE_COOL_STUFF } from '../../../../../constants/message';

type Props = {
  collection: Collection & AuthorizedCollection,
};

const CollectedView = ({ collection }: Props) => {
  const images = React.useMemo(() => getImagesData(collection), [collection]);
  return (
    <FluidContainer>
      <ParcelInfo logo={collection.customerImageLogo}>
        <TitleWithNumber
          title={`Your collection number: ${collection.collectionNumber}`}
        />
        <TrackingStatus status={collection.collectionStatusFull} />
        {images && (
          <DeliveryImages
            images={images}
            entityCode={collection.collectionCode}
          />
        )}
      </ParcelInfo>
    </FluidContainer>
  );
};

const headerMenuActions = props =>
  [
    trackAnotherCollectionHeader(props),
    props.isDriverAssigned && learnMoreAboutDriverHeader(props),
    collectionDetailsHeader(props),
    getDpdAppHeader(props),
    backToDpdSiteHeader(props),
  ].filter(action => !!action);

const speedDealActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
  backToDpdSiteSpeedDeal(props),
];

export default compose(
  withRouter,
  withCollection,
  withFetchCollectionDetails({
    fetch: collection =>
      RouteActions.fetchRouteByCode(getRouteCode(collection)),
    dataPropMapper: route => ({
      route,
      isDriverAssigned: isDriverAssigned(route),
    }),
  }),
  withPageControl({
    trackable: trackable(COLLECTED_VIEW),
    headerMenuActions,
    speedDealActions,
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
  })
)(CollectedView);
