// @flow
import { ORM } from 'redux-orm';
import { Collection } from './Collection/models';
import { Route } from './Route/models';
import { Driver } from './Driver/models';
import { Action } from './Action/models';

const orm = new ORM({
  stateSelector: state => state.orm,
});

orm.register(Collection, Route, Driver, Action);

export default orm;
