// @flow
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes/constants';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

import { withCollection } from '../Collection/hocs';
import { COLLECTION_EVENTS, trackable } from '../../constants/analytics';
import {
  trackAnotherCollectionHeader,
  viewTrackingHeader,
  changeCollectionHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  trackAnotherCollectionSpeedDeal,
  viewTrackingSpeedDeal,
  backToDpdSiteSpeedDeal,
} from '../controlActions';
import { COLLECTION_INFO } from '../../constants/message';
import CollectionEventsDesktop from './CollectionEventsDesktop';
import CollectionEventsMobile from './CollectionEventsMobile';
import { getBackgroundImage } from '../../utils/images';
import withFunctionalityLog from '../../HOCs/withFunctionalityLog';

const headerMenuActions = props => [
  trackAnotherCollectionHeader(props),
  viewTrackingHeader(props),
  changeCollectionHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

const speedDealActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  viewTrackingSpeedDeal(props),
  backToDpdSiteSpeedDeal(props),
];

const CollectionEvents = compose(
  withRouter,
  withCollection,
  withPageControl({
    title: COLLECTION_INFO,
    trackable: trackable(COLLECTION_EVENTS),
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
    backgroundOpacity: 0.87,
    headerMenuActions,
    speedDealActions,
  }),
  withFunctionalityLog('5Ag1FZUMwI6V8TxKYDvX')
)(isMobile ? CollectionEventsMobile : CollectionEventsDesktop);

export default CollectionEvents;
