// @flow

import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

import Neighbour from '@dpdgroupuk/fmx-ui/components/Icons/Neighbour';
import { getAddressLine } from '@dpdgroupuk/fmx-ui/utils/address';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: typography.pxToRem(15),
  },
  title: {
    fontSize: typography.pxToRem(19),
    lineHeight: typography.pxToRem(16),
    letterSpacing: 0.44,
    textAlign: 'center',
    marginRight: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      lineHeight: 0.92,
      marginRight: typography.pxToRem(10),
    },
  },
  button: {
    color: '#1d9ad0',
    fontSize: typography.pxToRem(13),
    letterSpacing: 0.44,
    textAlign: 'center',
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(11),
    },
  },
  icon: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(25),
      marginRight: typography.pxToRem(10),
    },
    fontSize: typography.pxToRem(30),
    marginRight: typography.pxToRem(20),
    '& .neighborIcon': {
      fill: '#5ba16b',
    },
  },
}));

type Props = {
  onNeighbourChange: Function,
  data: Object,
};

export default ({ onNeighbourChange, data: { address } }: Props) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.mainWrapper}>
        <Neighbour className={styles.icon} />
        <Typography className={styles.title}>
          {getAddressLine(address)}
        </Typography>
        <Link
          component="button"
          onClick={onNeighbourChange}
          className={styles.button}
        >
          CHANGE
        </Link>
      </div>
    </>
  );
};
