// @flow
import { createContext, useContext } from 'react';
import { defaultThemeName } from '@dpdgroupuk/fmx-ui/themes';

import type { Config } from './types';

export const defaultContextValue: Config = {
  deliveryEducation: [],
  themeName: defaultThemeName,
};
export const ConfigContext = createContext<Config>(defaultContextValue);

export const useRemoteConfig = (): { [string]: any } =>
  useContext(ConfigContext);
