import * as ROUTES from '../constants/routes';
import * as MESSAGE from '../constants/message';
import * as EXTERNAL_ROUTES from '../constants/externalRoutes';
import { SPEED_DEAL, HEADER } from '../constants/analytics';
import { pathToAction } from '../utils/path';
import {
  getLocationEvents,
  getLocationTracking,
  getLocationDriverProfile,
  getLocationCollectionOptions,
} from './Collection/actions/location';
import { getDriverId } from '../models/route';

export const backToDpdSite = (props, actionId: string | object) => [
  MESSAGE.BACK_TO_DPD_SITE,
  {
    href: props.theme.dpdSiteUrl,
    target: '_blank',
  },
  actionId,
];

export const backToDpdSiteHeader = props =>
  backToDpdSite(props, HEADER.BACK_TO_SITE);

export const backToDpdSiteSpeedDeal = props =>
  pathToAction(props, backToDpdSite(props, SPEED_DEAL.BACK_TO_SITE));

export const getDpdAppSpeedDeal = props =>
  pathToAction(props, getDpdApp(props, SPEED_DEAL.BACK_TO_SITE));

export const getDpdApp = (_: any, actionId: string | object) => [
  MESSAGE.GET_DPD_APP,
  {
    href: EXTERNAL_ROUTES.DPD_LP_YOURDPD,
    target: '_blank',
  },
  actionId,
];

export const getDpdAppHeader = props => getDpdApp(props, HEADER.DOWN_LOAD_APP);

export const trackAnotherCollection = (_: any, actionId: string | object) => [
  MESSAGE.TRACK_ANOTHER_COLLECTION,
  {
    pathname: ROUTES.REFERENCE_ENTRY,
  },
  actionId,
];

export const trackAnotherCollectionSpeedDeal = props =>
  pathToAction(
    props,
    trackAnotherCollection(props, SPEED_DEAL.TRACK_ANOTHER_COLLECTION)
  );

export const trackAnotherCollectionHeader = props =>
  trackAnotherCollection(props, HEADER.TRACK_ANOTHER_COLLECTION);

export const learnMoreAboutDriver = (
  { collection, route }: any,
  actionId: string | object,
  message
) => [
  message,
  getLocationDriverProfile({
    collectionId: collection.collectionCode,
    driverId: getDriverId(route),
  }),
  actionId,
];

export const learnMoreAboutDriverHeader = props =>
  learnMoreAboutDriver(
    props,
    HEADER.LEARN_MORE_ABOUT_DRIVER,
    MESSAGE.LEARN_MORE_ABOUT_THE_DRIVER
  );

export const learnMoreAboutDriverSpeedDeal = props =>
  pathToAction(
    props,
    learnMoreAboutDriver(
      props,
      SPEED_DEAL.LEARN_MORE_ABOUT_DRIVER,
      MESSAGE.LEARN_MORE_ABOUT_DRIVER
    )
  );

export const viewTracking = (
  { collection }: any,
  actionId: string | object
) => [
  MESSAGE.VIEW_TRACKING,
  getLocationTracking({
    collectionId: collection.collectionCode,
  }),
  actionId,
];

export const viewTrackingHeader = props =>
  viewTracking(props, HEADER.VIEW_TRACKING);

export const viewTrackingSpeedDeal = props =>
  pathToAction(props, viewTracking(props, SPEED_DEAL.VIEW_TRACKING));

export const changeCollection = (
  { collection }: any,
  actionId: string | object
) => [
  MESSAGE.CHANGE_COLLECTION,
  getLocationCollectionOptions({
    collectionId: collection.collectionCode,
  }),
  actionId,
];

export const changeCollectionHeader = props =>
  changeCollection(props, HEADER.CHANGE_COLLECTION);

export const changeCollectionSpeedDeal = props =>
  pathToAction(props, changeCollection(props, SPEED_DEAL.CHANGE_COLLECTION));

export const collectionDetails = (
  { collection }: any,
  actionId: string | object
) => [
  MESSAGE.COLLECTION_DETAILS,
  getLocationEvents({
    collectionId: collection.collectionCode,
  }),
  actionId,
];

export const collectionDetailsHeader = props =>
  collectionDetails(props, HEADER.COLLECTION_INFO);

export const collectionDetailsSpeedDeal = props =>
  pathToAction(props, collectionDetails(props, SPEED_DEAL.COLLECTION_INFO));

export const doSomethingDifferent = (
  { collection }: any,
  actionId: string | object
) => [
  MESSAGE.DO_SOMETHING_DIFFERENT,
  getLocationCollectionOptions({
    collectionId: collection.collectionCode,
  }),
  actionId,
];

export const doSomethingDifferentSpeedDeal = props =>
  pathToAction(
    props,
    doSomethingDifferent(props, SPEED_DEAL.DO_SOMETHING_DIFFERENT)
  );

export const getCollectionOptionHeaderControlActions = props => [
  trackAnotherCollectionHeader(props),
  viewTrackingHeader(props),
  collectionDetailsHeader(props),
  changeCollectionHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

export const getCollectionOptionSpeedDealControlActions = props => [
  doSomethingDifferentSpeedDeal(props),
  viewTrackingSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
];

export const getCompletedOptionSpeedDealControlActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  viewTrackingSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
];
