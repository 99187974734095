// @flow

import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Action extends Model {
  // eslint-disable-next-line no-use-before-define
  static reducer(action: PayloadAction, Action: Action) {
    if (action.type === ACTIONS.FETCH_ACTIONS_BY_CODE.SUCCESS) {
      Action.create({
        ...action.payload,
      });
    }
  }

  static modelName = ENTITY_NAME.ACTION;

  static fields = {
    collectionCode: attr(),
  };

  static options = {
    idAttribute: 'collectionCode',
  };
}
