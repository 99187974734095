import { withProps } from 'recompose';

import * as MESSAGES from '../../../../constants/message';
import { getCollectionNotificationDetails } from '../../../../models/collection';
import ContactDetailsFormComponent from '../../components/GetContactDetailsStep';

export default withProps(({ collection, submitWizard, addStepData }) => ({
  title: MESSAGES.PROVIDE_CONTACT_INFO,
  initialValues: {
    contactName: getCollectionNotificationDetails(collection).contact,
  },
  onChange: values => {
    addStepData({
      notificationDetails: {
        contactName: values.contactName,
        email: values.contactEmail,
        sms: values.contactPhone,
      },
    });
  },
  onSubmit: submitWizard,
}))(ContactDetailsFormComponent);
