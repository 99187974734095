// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import DriverProfileComponent from '@dpdgroupuk/fmx-ui/components/DriverProfile';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';

import { DRIVER_PROFILE, trackable } from '../../constants/analytics';
import * as MESSAGES from '../../constants/message';
import {
  trackAnotherCollectionHeader,
  viewTrackingHeader,
  collectionDetailsHeader,
  changeCollectionHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  viewTrackingSpeedDeal,
  collectionDetailsSpeedDeal,
  changeCollectionSpeedDeal,
} from '../controlActions';
import { goToTracking } from '../historyActions';
import { getCollectionData } from '../../store/orm/Collection/selectors';
import { getDriverData } from '../../store/orm/Driver/selectors';
import { fetchCacheableDriverRelatedData } from './actions';
import { getRouteData } from '../../store/orm/Route/selectors';
import { getRouteCode } from '../../models/collection';
import { isElectric } from '../../models/driver';
import withFetch from '../../HOCs/withFetch';
import { getBackgroundImage } from '../../utils/images';
import withFunctionalityLog from '../../HOCs/withFunctionalityLog';

const headerMenuActions = props => [
  trackAnotherCollectionHeader(props),
  viewTrackingHeader(props),
  collectionDetailsHeader(props),
  changeCollectionHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

const speedDealActions = props => [
  viewTrackingSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
  changeCollectionSpeedDeal(props),
];

const mapStateToProps = (state, { match }) => {
  const { collectionId, driverId } = match.params;
  const collection = getCollectionData(state, collectionId);
  const routeCode = getRouteCode(collection);
  const driver = getDriverData(state, driverId);

  return {
    driver,
    route: getRouteData(state, routeCode),
    isVehicleElectric: isElectric(driver),
    collection,
    entityCode: collection.collectionCode,
  };
};

const DriverProfile = compose(
  withRouter,
  withFetch(fetchCacheableDriverRelatedData),
  connect(mapStateToProps),
  withPageControl({
    title: MESSAGES.LEARN_MORE_ABOUT_DRIVER,
    backgroundImage: getBackgroundImage({
      desktopImage: IMAGES.DRIVER_BG,
      mobileImage: IMAGES.DRIVER_BG_MOBILE,
    }),
    backgroundOpacity: 0.87,
    trackable: trackable(DRIVER_PROFILE),
    headerMenuActions,
    speedDealActions,
  }),
  withProps(props => ({
    goToTracking: () => goToTracking(props),
  })),
  withTrackProps({
    goToTracking: DRIVER_PROFILE.GO_TO_TRACKING_MAP,
  }),
  withFunctionalityLog('tQcKAb6IGTZbpsADrPvR')
)(DriverProfileComponent);

export default DriverProfile;
