import { omitBy, isNil } from 'lodash';
import { getAnyway } from '@dpdgroupuk/fmx-ui/utils/helpers';

export const getRouteCode = collection =>
  collection.collectionDetails.route.routeCode;

export const getCollectionNotificationDetails = collection =>
  getAnyway(collection, 'collectionDetails.notificationDetails', {});

export const getNotificationDetails = collection => {
  const collectionNotificationDetails = getCollectionNotificationDetails(
    collection
  );

  return omitBy(
    {
      contactName: collectionNotificationDetails.contact,
      email: collectionNotificationDetails.email,
      sms: collectionNotificationDetails.mobile,
    },
    isNil
  );
};

export const hasCollectionNotificationDetails = collection => {
  const notificationDetails = getCollectionNotificationDetails(collection);
  return !!(
    notificationDetails.contact &&
    !!(notificationDetails.email || notificationDetails.mobile)
  );
};
