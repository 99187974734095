import { SAFE_PLACE_TYPE } from '@dpdgroupuk/redback-enums';
import {
  Garage,
  Porch,
  RearPorch,
  SafePlace,
  Shed,
} from '@dpdgroupuk/fmx-ui/components/Icons';
import { DEFAULT_DATE_FORMAT } from '@dpdgroupuk/fmx-ui/utils/date';
import { parseDate } from '../../utils/moment';

export const calculateChangeCollectionDate = (selectedDate, collectionDate) => {
  const dateAsString = selectedDate || collectionDate;

  return parseDate(dateAsString, DEFAULT_DATE_FORMAT);
};

export const calculateInstruction = (safePlace, driverInstructions) => {
  if (safePlace.id === SAFE_PLACE_TYPE.OTH) {
    return driverInstructions
      ? `${safePlace.name} - ${driverInstructions}`
      : safePlace.name;
  }
  return driverInstructions;
};

export const getSafePlaceIcon = safePlace => {
  switch (safePlace) {
    case SAFE_PLACE_TYPE.FPC:
      return Porch;
    case SAFE_PLACE_TYPE.GAR:
      return Garage;
    case SAFE_PLACE_TYPE.OBL:
      return Shed;
    case SAFE_PLACE_TYPE.RPC:
      return RearPorch;
    case SAFE_PLACE_TYPE.OTH:
      return SafePlace;
    default:
      throw new Error('Icon not found');
  }
};
