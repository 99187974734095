import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import { SAFE_PLACE_TYPE, SAFE_PLACE_NAME } from '@dpdgroupuk/redback-enums';
import Typography from '@material-ui/core/Typography';
import Input from '@dpdgroupuk/fmx-ui/components/Input';
import SafePlaceItem, {
  createSafePlace,
  SafePlace,
} from '@dpdgroupuk/fmx-ui/components/SafePlaceItem';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import AcceptFullResponsibility from '@dpdgroupuk/fmx-ui/components/AcceptFullResponsibility';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import { noop } from '../../../../utils/function';
import {
  SUBMIT,
  SAFE_PLACE_PLACEHOLDER,
  PLEASE_CONFIRM_FOLLOWING,
  SAFE_PLACE_CONFIRM_MESSAGE,
} from '../../../../constants/message';
import { SFP_STEPS } from '../../steps';
import type { AuthorizedCollection } from '../../../../types/Collection';

import ChangeDate from '../../components/ChangeDate';
import { WIZARD_SFP, WIZARD_COMMON } from '../../../../constants/analytics';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  list: {
    [breakpoints.down('sm')]: {
      width: typography.pxToRem(240),
    },
    width: typography.pxToRem(280),
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(18),
      marginTop: typography.pxToRem(30),
      marginBottom: typography.pxToRem(20),
    },
    fontSize: typography.pxToRem(21),
    lineHeight: 1.49,
    letterSpacing: typography.pxToRem(0.44),
    marginTop: typography.pxToRem(40),
    marginBottom: typography.pxToRem(20),
  },
  subtitle: {
    fontSize: typography.pxToRem(13),
    lineHeight: 1.33,
    letterSpacing: typography.pxToRem(0.48),
    color: '#b7b9bd',
    textAlign: 'center',
    maxWidth: typography.pxToRem(230),
  },
  restriction: {
    fontSize: typography.pxToRem(12),
    lineHeight: 1.33,
    textAlign: 'left',
  },
  inputTitle: {
    [breakpoints.down('sm')]: {
      fontSize: 16,
    },
    fontSize: typography.pxToRem(19),
    lineHeight: 0.84,
    letterSpacing: typography.pxToRem(0.44),
    margin: `${typography.pxToRem(20)} 0 ${typography.pxToRem(10)}`,
  },
  input: {
    [breakpoints.down('sm')]: {
      width: typography.pxToRem(240),
      marginTop: typography.pxToRem(10),
    },
    width: typography.pxToRem(275),
    margin: `${typography.pxToRem(5)} 0 ${typography.pxToRem(20)}`,
    border: 'solid 1px rgba(255, 255, 255, 0.44)',

    '& input': {
      textIndent: typography.pxToRem(5),
      fontSize: typography.pxToRem(13),
      fontWeight: 300,
      lineHeight: 0.94,
      '&::placeholder': {
        color: 'solid 1px rgba(255, 255, 255, 0.44)',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginBottom: typography.pxToRem(40),
  },
  acceptedResponsibility: {
    justifyContent: 'start',
  },
  confirmSubtitle: {
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(11),
    },
    fontSize: typography.pxToRem(12),
    color: '#b7b9bd',
    textAlign: 'left',
    marginTop: typography.pxToRem(5),
    marginBottom: typography.pxToRem(25),
    whiteSpace: 'pre-wrap',
  },
}));

type Props = {
  data: Object,
  safePlaces: Array<SafePlace>,
  collection: AuthorizedCollection,
  dates: Array<string>,
  onSubmit: Function,
  onSelect: Function,
  onDateChange: Function,
  handleInputChange: Function,
};

const SelectSafePlaceStep = ({
  data,
  safePlaces,
  collection,
  dates,
  onSubmit,
  onSelect,
  onDateChange,
  handleInputChange,
}: Props) => {
  const { safePlace, instruction } = data;
  const styles = useStyles();
  const whitespaceInstruction = !/(.|\s)*\S(.|\s)*/.test(instruction);
  const [acceptedResponsibility, setAcceptedResponsibility] = React.useState(
    false
  );
  const isSafePlaceInvalid =
    !acceptedResponsibility ||
    !safePlace ||
    (safePlace.id === SAFE_PLACE_TYPE.OTH && whitespaceInstruction);

  const handleSelect = React.useCallback(
    safePlace => {
      onSelect(safePlace);
      setAcceptedResponsibility(false);
    },
    [onSelect, setAcceptedResponsibility]
  );

  const handleOtherChange = React.useCallback(
    event => {
      handleInputChange(event.target.value);

      if (acceptedResponsibility) {
        setAcceptedResponsibility(false);
      }
    },
    [handleInputChange, acceptedResponsibility, setAcceptedResponsibility]
  );

  return (
    <>
      <ChangeDate
        day={dates && dates[0]}
        dates={dates}
        collection={collection}
        onDateChange={onDateChange}
      />
      <Typography className={styles.title}>
        Select your safe place location
      </Typography>
      <Typography className={styles.subtitle}>
        If you’re not at home, we can collect your parcel from a safe place
      </Typography>
      <div className={styles.list}>
        {safePlaces.map((place: SafePlace) => {
          return (
            <SafePlaceItem
              key={place.id}
              place={place}
              onSelect={handleSelect}
              selected={place === safePlace}
            />
          );
        })}
      </div>
      <div className={styles.list}>
        <Typography className={styles.inputTitle}>OTHER SAFE PLACE</Typography>
        <Typography className={styles.restriction}>
          Max 15 characters
        </Typography>
        <Input
          value={instruction}
          className={styles.input}
          onChange={handleOtherChange}
          name={SAFE_PLACE_NAME.OTH}
          placeholder={SAFE_PLACE_PLACEHOLDER}
          inputProps={{ maxLength: 15 }}
        />
        <AcceptFullResponsibility
          title={PLEASE_CONFIRM_FOLLOWING}
          selected={acceptedResponsibility}
          onSelect={() => setAcceptedResponsibility(!acceptedResponsibility)}
          className={styles.acceptedResponsibility}
        />
        <Typography className={styles.confirmSubtitle}>
          {SAFE_PLACE_CONFIRM_MESSAGE}
        </Typography>
      </div>
      <Button
        onClick={onSubmit}
        classes={{ root: styles.button }}
        style={{ visibility: isSafePlaceInvalid ? 'hidden' : 'visible' }}
      >
        {SUBMIT}
      </Button>
    </>
  );
};

SelectSafePlaceStep.defaultProps = {
  data: { day: '' },
  goToStep: noop,
  addStepData: noop,
  safePlaces: [
    createSafePlace(SAFE_PLACE_TYPE.FPC),
    createSafePlace(SAFE_PLACE_TYPE.GAR),
    createSafePlace(SAFE_PLACE_TYPE.OBL),
    createSafePlace(SAFE_PLACE_TYPE.RPC),
  ],
};

export default compose(
  withProps(({ goToStep, addStepData }) => ({
    onSubmit: () => goToStep(SFP_STEPS.DESCRIBE_SAFE_PLACE),
    onSelect: safePlace =>
      addStepData({
        safePlace,
        instruction: '',
      }),
    onDateChange: () => goToStep(SFP_STEPS.SELECT_DAY),
    handleInputChange: value => {
      value.length <= 15 &&
        addStepData({
          safePlace: value ? createSafePlace(SAFE_PLACE_TYPE.OTH, value) : null,
          instruction: value,
        });
    },
  })),
  withTrackProps({
    onSubmit: WIZARD_SFP.ON_SUBMIT,
    onSelect: WIZARD_SFP.ON_SELECT,
    onDateChange: WIZARD_COMMON.ON_DATE_CHANGE,
  })
)(SelectSafePlaceStep);
