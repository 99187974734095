export const COLLECTION = '/collections/:collectionId';
export const OPTION_CODE = '/:actionCode';
export const ROUTES = {
  REFERENCE_ENTRY: '/',
  COLLECTION_OPTIONS: `${COLLECTION}/options`,
  COLLECTION_COMPLETED: `${COLLECTION}/completed`,
  CHANGE_COLLECTION: `${COLLECTION}/options${OPTION_CODE}`,
  DRIVER_PROFILE: `${COLLECTION}/drivers/:driverId`,
  EVENTS: `${COLLECTION}/events`,
  TRACKING: COLLECTION,
};
