// @flow

import api from './api';

type User = {
  userType: string,
  origin: string,
};

type RequestBody = {|
  collectionCode: string,
  postcode: string,
  origin: string,
|};

export const login = (
  collectionCode: string,
  postcode: string,
  token: string
) => {
  const url = new URL(window.location.href);
  const origin = url.searchParams.get('origin') || 'CRTK';
  return api.post<_, RequestBody, User>({
    path: '/login',
    headers: {
      'g-recaptcha-response': token,
    },
    body: {
      collectionCode,
      postcode,
      origin,
    },
  });
};
