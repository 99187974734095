// @flow

import { attr, Model } from 'redux-orm';
import type { PayloadAction } from '@dpdgroupuk/redux-action-creator';

import { ACTIONS } from './actions';
import { ENTITY_NAME } from '../constants';

export class Route extends Model {
  static reducer(action: PayloadAction, Route: any) {
    if (action.type === ACTIONS.FETCH_ROUTE_BY_CODE.SUCCESS) {
      Route.create({
        ...action.payload,
      });
    }
  }

  static modelName = ENTITY_NAME.ROUTE;

  static fields = {
    routeCode: attr(),
  };

  static options = {
    idAttribute: 'routeCode',
  };
}
