import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTrackerContext } from '@dpdgroupuk/react-event-tracker';

import { getLocationTracking } from './actions/location';
import { ROUTES } from './constants';
import Tracking from '../Tracking';
import CollectionEvents from '../CollectionEvents';
import DriverProfile from '../DriverProfile';
import CollectionOptions from '../CollectionOptions';
import type { Collection, AuthorizedCollection } from '../../types/Collection';
import ProtectedRoute from '../../HOCs/ProtectedRoute';
import CompletedScreen from '../ChangeCollectionOption/pages/Completed/CompletedScreen';
import { getSessionId } from '../../utils/session';

type RouteProps = {
  collection: Collection & AuthorizedCollection,
};

const Routes = ({ collection }: RouteProps) => {
  const tracker = useTrackerContext();

  React.useEffect(() => {
    tracker.setContext({
      collectionCode: collection ? collection.collectionCode : null,
      sessionId: getSessionId(),
    });

    return () => {
      tracker.setContext({
        collectionCode: null,
        sessionId: null,
      });
    };
  }, [collection, tracker]);

  return (
    <Switch>
      <ProtectedRoute
        path={ROUTES.COLLECTION_OPTIONS}
        component={CollectionOptions}
      />
      <ProtectedRoute
        path={ROUTES.COLLECTION_COMPLETED}
        component={CompletedScreen}
      />
      <ProtectedRoute path={ROUTES.DRIVER_PROFILE} component={DriverProfile} />
      <ProtectedRoute path={ROUTES.EVENTS} component={CollectionEvents} />
      <Route path={ROUTES.TRACKING} component={Tracking} />
      <Route
        children={() => (
          <Redirect
            to={getLocationTracking({
              collectionId: collection.collectionCode,
            })}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
