import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import ImagePreview from '../ImagePreview';
import SecondaryButton from '../SecondaryButton';
import { WIZARD_PHOTO } from '../../constants/analytics';

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  description: {
    width: typography.pxToRem(252),
    fontSize: typography.pxToRem(13),
    lineHeight: typography.pxToRem(19),
    letterSpacing: typography.pxToRem(0.48),
    color: palette.text.primary,
    paddingTop: typography.pxToRem(30),
    paddingBottom: typography.pxToRem(30),
    fontWeight: 500,
    textAlign: 'center',
  },
  restriction: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(19),
    letterSpacing: typography.pxToRem(0.44),
    color: palette.text.primary,
    opacity: 0.4,
    textAlign: 'center',
  },
  label: {
    fontFamily: 'PlutoSansRegular',
    fontSize: typography.pxToRem(14),
    lineHeight: 1.29,
    letterSpacing: typography.pxToRem(0.5),
    color: palette.text.primary,
    fontWeight: 600,
    textAlign: 'center',
    paddingTop: typography.pxToRem(70),
  },
  separator: {
    fontFamily: 'PlutoSansRegular',
    fontSize: typography.pxToRem(12.5),
    letterSpacing: typography.pxToRem(0.45),
    color: '#a6a9ad',
    overflow: 'hidden',
    display: 'block',
    textAlign: 'center',
    paddingTop: typography.pxToRem(30),
    paddingBottom: typography.pxToRem(30),
    '&:after': {
      left: '1em',
      marginRight: '-50%',
    },
    '&:before': {
      right: '1em',
      marginLeft: '-50%',
    },
    '&:after, &:before': {
      backgroundColor: '#a6a9ad',
      content: '""',
      display: 'inline-block',
      height: 1,
      position: 'relative',
      verticalAlign: 'middle',
      width: typography.pxToRem(60),
      opacity: 0.5,
    },
  },
  image: {
    margin: typography.pxToRem(20),
  },
  submitButton: {
    marginTop: typography.pxToRem(20),
    marginBottom: typography.pxToRem(30),
    width: typography.pxToRem(160),
  },
  skipButton: {
    [breakpoints.down('sm')]: {
      marginBottom: typography.pxToRem(20),
    },
    marginTop: typography.pxToRem(20),
    marginBottom: typography.pxToRem(40),
    width: '100%',
  },
  dropzone: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'block',
  },
  mobileButton: {
    [breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: typography.pxToRem(40),
      fontSize: typography.pxToRem(12.5),
      lineHeight: typography.pxToRem(18),
      letterSpacing: typography.pxToRem(0.45),
    },
    display: 'none',
  },
}));

type Props = {
  description: string,
  photoUrl: string,
  onSubmit: any => any,
  onSkip: any => any,
  onReplacePhoto: any => any,
  onDropAccepted: any => any,
};

const DropZone = ({
  onSubmit,
  onReplacePhoto,
  onSkip,
  onDropAccepted,
  description,
  photoUrl,
}: Props) => {
  const tracker = useTracker();
  const styles = useStyles();

  const onTrackableDrop = React.useCallback(
    e => {
      e.preventDefault();
      tracker.logEvent(WIZARD_PHOTO.ON_DND);
    },
    [tracker]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
    maxSize: 5000000,
    onDropAccepted,
    multiple: false,
  });
  useGoogleReCaptchaVisibleToggle();

  const onTrackableBrowse = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_PHOTO.ON_BROWSE);
      return open(...args);
    },
    [open, tracker]
  );

  const onTrackableSkip = React.useCallback(
    (...args) => {
      tracker.logEvent(WIZARD_PHOTO.ON_SKIP);
      return onSkip(...args);
    },
    [onSkip, tracker]
  );

  return (
    <>
      <Typography className={styles.description}>{description}</Typography>
      <Typography className={styles.restriction}>
        *JPEG or PNG only and no bigger than 5mb
      </Typography>
      {photoUrl ? (
        <>
          <ImagePreview className={styles.image} photoUrl={photoUrl} />
          <SecondaryButton
            onClick={onReplacePhoto}
            buttonText="REPLACE PHOTO"
          />
          <Button onClick={onSubmit} className={styles.submitButton}>
            Submit
          </Button>
        </>
      ) : (
        <div onDrop={onTrackableDrop}>
          <div {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            <div>
              <Typography className={styles.label}>DRAG PHOTO HERE</Typography>
              <Typography className={styles.separator}> OR </Typography>
            </div>
            <Button onClick={onTrackableBrowse}>BROWSE FILES</Button>
            <br />
          </div>
          <SecondaryButton
            onClick={onTrackableSkip}
            buttonText="SKIP PHOTO"
            className={styles.skipButton}
          />
          <Button onClick={open} className={styles.mobileButton}>
            ADD A PHOTO
          </Button>
        </div>
      )}
    </>
  );
};

export default DropZone;
