// @flow
import React from 'react';
import { get } from 'lodash';
import NotAuthorised from './NotAuthorised';
import DefaultError from './DefaultError';
import TimeoutError from './TimeoutError';

type Props = {
  error: any,
};

export default ({ error }: Props) => {
  switch (get(error, 'errorCode')) {
    case 408:
      return <TimeoutError />;
    case 401:
      return <NotAuthorised />;
    default:
      return <DefaultError />;
  }
};
