// @flow

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncAction } from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../../services';
import { STATUS_CODE } from '../../../utils/httpStatus';

type ActionTypes = {|
  FETCH_DRIVER: AsyncAction,
  FETCH_DRIVER_FEEDBACK: AsyncAction,
|};

export const ACTIONS = createActionTypes<ActionTypes>('DRIVER', {
  FETCH_DRIVER: createAsyncActionTypes('FETCH_DRIVER'),
  FETCH_DRIVER_FEEDBACK: createAsyncActionTypes('FETCH_DRIVER_FEEDBACK'),
});

export const fetchDriver = createAsyncAction(
  (driverCode: string) =>
    fetchTimeoutHandler(apiService.getDriverByDriverCode(driverCode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_DRIVER
);

export const fetchDriverFeedback = createAsyncAction(
  (driverCode: string) =>
    fetchTimeoutHandler(apiService.getDriverFeedbackByDriverCode(driverCode))
      .then(({ data }) => ({ ...data, driverCode }))
      .catch(error => {
        if (error.statusCode === STATUS_CODE.NOT_FOUND) {
          return {
            driverCode,
            feedback: [],
            compliments: {
              deliveredWithCare: 0,
              wentExtraMile: 0,
              friendlyManner: 0,
              respectedMyProperty: 0,
            },
          };
        }
      }),
  ACTIONS.FETCH_DRIVER_FEEDBACK
);
