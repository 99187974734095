import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { CollectionOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import Typography from '@material-ui/core/Typography';

import {
  CONFIRMATION_SCREEN,
  trackable,
} from '../../../../constants/analytics';
import type { AuthorizedCollection } from '../../../../types/Collection';
import {
  TRACK_ANOTHER_COLLECTION,
  CANCEL_COLLECTION,
  YOUR_COLLECTION_NUMBER,
} from '../../../../constants/message';
import {
  getCollectionOptionHeaderControlActions,
  getCompletedOptionSpeedDealControlActions,
} from '../../../controlActions';
import { trackAnotherCollection } from '../../../historyActions';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  button: {
    height: typography.pxToRem(36),
    width: typography.pxToRem(260),
    fontSize: typography.pxToRem(13.5),
  },
  collectionNumber: {
    opacity: 0.4,
    fontSize: typography.pxToRem(18),
    fontWeight: 300,
    margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(50)}`,
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(15),
      margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(30)}`,
    },
  },
}));

type Props = {
  actionCode?: string,
  trackAnotherCollection: Function,
  collection: AuthorizedCollection,
};

const CompleteStepComponent = ({
  collection,
  actionCode,
  trackAnotherCollection,
}: Props) => {
  const styles = useStyles();

  return (
    <>
      <DeliveryInfo
        actionCode={actionCode}
        title={`As requested, your collection has\nnow been cancelled`}
        getOptionIcon={() => CollectionOptionIcon}
      />
      <Typography className={styles.collectionNumber}>
        {`${YOUR_COLLECTION_NUMBER}: ${collection.collectionNumber}`}
      </Typography>
      <Button className={styles.button} onClick={trackAnotherCollection}>
        {TRACK_ANOTHER_COLLECTION}
      </Button>
    </>
  );
};

const CompleteStep = compose(
  withRouter,
  withProps(props => ({
    trackAnotherCollection: () => trackAnotherCollection(props),
  })),
  withPageControl({
    title: CANCEL_COLLECTION,
    backgroundImage: IMAGES.MAP,
    headerMenuActions: getCollectionOptionHeaderControlActions,
    trackable: trackable(CONFIRMATION_SCREEN),
    speedDealActions: getCompletedOptionSpeedDealControlActions,
  }),
  withTrackProps({
    trackAnotherCollection: CONFIRMATION_SCREEN.TRACK_ANOTHER_COLLECTION,
  })
)(CompleteStepComponent);

export default CompleteStep;
