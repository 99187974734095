import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import * as ENUM from '@dpdgroupuk/redback-enums';
import OptionsList from '@dpdgroupuk/fmx-ui/components/OptionsList';
import { CollectionOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import type { Action } from '@dpdgroupuk/fmx-ui/types';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { DIALOG_TYPE } from '@dpdgroupuk/fmx-ui/constants/dialogTypes';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import { withCollection } from '../Collection/hocs';
import * as MESSAGES from '../../constants/message';
import {
  CANCEL_MODAL,
  COLLECTION_OPTIONS,
  trackable,
} from '../../constants/analytics';
import {
  trackAnotherCollectionHeader,
  viewTrackingHeader,
  collectionDetailsHeader,
  getDpdAppHeader,
  backToDpdSiteHeader,
  trackAnotherCollectionSpeedDeal,
  collectionDetailsSpeedDeal,
  viewTrackingSpeedDeal,
} from '../../pages/controlActions';
import { getCollectionActionsByDepotCode } from '../../store/orm/Action/selectors';
import { hasCollectionNotificationDetails } from '../../models/collection';
import { goToChangeCollection } from '../historyActions';
import { cancelCollection } from '../ChangeCollectionOption/pages/CancelСollection/actions';
import { withCollectionUpdate } from '../ChangeCollectionOption/hocs';
import DefaultError from '../ErrorScreen';

type Props = {
  collectionActions: Action,
  onOptionSelect: Function,
};

const CollectionOptionsComponent = ({
  collectionActions,
  onOptionSelect,
}: Props) => {
  useGoogleReCaptchaVisibleToggle();
  const noOptionsReturned =
    isEmpty(collectionActions.availableActions) &&
    isEmpty(collectionActions.unavailableActions);

  return noOptionsReturned ? (
    <DefaultError />
  ) : (
    <OptionsList
      title={MESSAGES.COLLECTION_OPTIONS_TITLE}
      subtitle={MESSAGES.CHOOSE_OPTION}
      unavailableOptionText={MESSAGES.UNAVAILABLE_OPTION}
      actions={collectionActions}
      onOptionSelect={onOptionSelect}
      getOptionIcon={() => CollectionOptionIcon}
    />
  );
};

const mapStateToProps = (state, { match }) => ({
  collectionActions: getCollectionActionsByDepotCode(
    state,
    match.params.collectionId
  ),
});

const headerMenuActions = props => [
  trackAnotherCollectionHeader(props),
  viewTrackingHeader(props),
  collectionDetailsHeader(props),
  getDpdAppHeader(props),
  backToDpdSiteHeader(props),
];

const speedDealActions = props => [
  trackAnotherCollectionSpeedDeal(props),
  viewTrackingSpeedDeal(props),
  collectionDetailsSpeedDeal(props),
];

const CollectionOptions = compose(
  withCollection,
  connect(mapStateToProps),
  withPageControl({
    title: MESSAGES.COLLECTION_OPTIONS,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(COLLECTION_OPTIONS),
    headerMenuActions,
    speedDealActions,
  }),
  withCollectionUpdate({
    actionCode: ENUM.COLLECTION_ACTION_CODE.CAN,
    changeCollectionAction: cancelCollection,
  }),
  withProps(props => {
    const { collection, updateCollection, onSubmitFailure } = props;
    const { showModal } = useModal();
    const tracker = useTracker();
    const isCancelCollectionSelected = actionCode =>
      actionCode.toUpperCase() === ENUM.COLLECTION_ACTION_CODE.CAN;
    const actionTypeId = actionCode =>
      COLLECTION_OPTIONS[actionCode.toUpperCase()];
    const onOptionSelect = React.useCallback(
      actionCode =>
        goToChangeCollection({
          ...props,
          actionCode,
        }),
      [props]
    );
    const handleOptionSelect = React.useCallback(
      actionCode =>
        isCancelCollectionSelected(actionCode) &&
        hasCollectionNotificationDetails(collection)
          ? showModal({
              type: DIALOG_TYPE.CONFIRMATION,
              title: MESSAGES.CONFIRM_CANCEL_COLLECTION,
              onConfirm: (data, query) =>
                updateCollection(data, query).catch(error =>
                  onSubmitFailure(error)
                ),
              analytics: CANCEL_MODAL,
            })
          : onOptionSelect(actionCode),
      [collection, onOptionSelect, showModal, updateCollection, onSubmitFailure]
    );

    const onOptionTrackableSelect = React.useCallback(
      (...args) => {
        tracker.logEvent(actionTypeId);
        return handleOptionSelect(...args);
      },
      [handleOptionSelect, tracker]
    );

    return {
      onOptionSelect: onOptionTrackableSelect,
    };
  })
)(CollectionOptionsComponent);

export default CollectionOptions;
