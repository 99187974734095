import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Loadable } from '@dpdgroupuk/fmx-ui/components/Loader';
import typeof { AuthorizedCollection } from '../types/Collection';

import ErrorScreen from '../pages/ErrorScreen';

type Fetch = (collection: AuthorizedCollection) => Promise<any>;
type DataPropMapper = string | (any => { [key: string]: any });
type Args = {
  fetch: Fetch,
  dataPropMapper: DataPropMapper,
};

type InjectedProps = {
  [key: string]: any,
};

export default function withFetchCollectionDetails<Config>({
  fetch,
  dataPropMapper,
}: Args) {
  let propsMapper = dataPropMapper;
  if (typeof dataPropMapper === 'string') {
    propsMapper = data => ({ [dataPropMapper]: data });
  }
  return (
    Component: React.AbstractComponent<{| ...Config, ...InjectedProps |}>
  ): React.AbstractComponent<Config> => {
    return function WrapperComponent(props: Config) {
      const dispatch = useDispatch();
      const [promiseFn] = React.useState(() => () =>
        dispatch(fetch(props.collection))
      );
      const asyncDataProps = React.useMemo(
        () => ({
          promiseFn,
          persistData: false,
        }),
        [promiseFn]
      );

      return (
        <Loadable asyncDataProps={asyncDataProps} errorScreen={ErrorScreen}>
          {data => {
            const newProps = {
              ...props,
              ...propsMapper(data),
            };
            return <Component {...newProps} />;
          }}
        </Loadable>
      );
    };
  };
}
