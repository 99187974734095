// @flow
import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import DeliveryInfo from '@dpdgroupuk/fmx-ui/components/DeliveryInfo';
import { CollectionOptionIcon } from '@dpdgroupuk/fmx-ui/components/OptionIcon';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';
import Typography from '@material-ui/core/Typography';

import { formatOrdinalNumber } from '@dpdgroupuk/fmx-ui/utils/string';
import { fullDateValue } from '@dpdgroupuk/fmx-ui/utils/date';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';

import type { AuthorizedCollection } from '../../../../types/Collection';
import {
  getCollectionOptionHeaderControlActions,
  getCompletedOptionSpeedDealControlActions,
} from '../../../controlActions';
import {
  COLLECT_FROM_SAFE_PLACE,
  TRACK_ANOTHER_COLLECTION,
  YOUR_COLLECTION_NUMBER,
} from '../../../../constants/message';
import { trackAnotherCollection } from '../../../historyActions';
import ImagePreview from '../../../../components/ImagePreview';
import {
  CONFIRMATION_SCREEN,
  trackable,
} from '../../../../constants/analytics';

type Props = {
  actionCode?: string,
  data?: Object,
  collection: AuthorizedCollection,
  trackAnotherCollection: Function,
};
const useStyles = makeStyles(({ typography, breakpoints }) => ({
  button: {
    margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(20)}`,
    width: typography.pxToRem(260),
    fontFamily: 'PlutoSansDPD',
    fontSize: typography.pxToRem(14),
    lineHeight: 1.2,
    letterSpacing: typography.pxToRem(0.9),
    [breakpoints.down('sm')]: {
      marginTop: typography.pxToRem(20),
    },
  },
  collectionNumber: {
    opacity: 0.4,
    fontSize: typography.pxToRem(18),
    fontWeight: 300,
    [breakpoints.down('sm')]: {
      fontSize: typography.pxToRem(15),
    },
  },
  image: {
    marginBottom: typography.pxToRem(20),
    [breakpoints.down('sm')]: {
      marginBottom: typography.pxToRem(15),
    },
  },
  date: {
    paddingLeft: typography.pxToRem(120),
    paddingRight: typography.pxToRem(120),
    [breakpoints.down('xs')]: {
      paddingLeft: typography.pxToRem(15),
      paddingRight: typography.pxToRem(15),
    },
  },
}));

const CompleteStepComponent = ({
  actionCode,
  data = {},
  collection,
  trackAnotherCollection,
}: Props) => {
  const styles = useStyles();
  const { photoUrl, day } = data;
  const { year, month, dayOfWeek, dayNumber } = fullDateValue(
    day || collection.collectionDate
  );
  const title = `As requested, your parcel will now be collected from your safe place at`;
  const subTitle = collection ? (
    <div className={styles.date}>
      {getAddressWithPostcode(collection.collectionDetails.address)}
      {
        <>
          <br />
          on {dayOfWeek} the {formatOrdinalNumber(dayNumber)} {month} {year}
        </>
      }
    </div>
  ) : (
    ''
  );

  return (
    <>
      <DeliveryInfo
        actionCode={actionCode}
        title={title}
        subTitle={subTitle}
        getOptionIcon={() => CollectionOptionIcon}
      />
      <ImagePreview photoUrl={photoUrl} className={styles.image} withBorder />
      <Typography className={styles.collectionNumber}>
        {`${YOUR_COLLECTION_NUMBER}: ${collection.collectionNumber}`}
      </Typography>
      <Button className={styles.button} onClick={trackAnotherCollection}>
        {TRACK_ANOTHER_COLLECTION}
      </Button>
    </>
  );
};

const CompleteStep = compose(
  withRouter,
  withProps(props => ({
    trackAnotherCollection: () => trackAnotherCollection(props),
  })),
  withPageControl({
    title: COLLECT_FROM_SAFE_PLACE,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(CONFIRMATION_SCREEN),
    headerMenuActions: getCollectionOptionHeaderControlActions,
    speedDealActions: getCompletedOptionSpeedDealControlActions,
  }),
  withTrackProps({
    trackAnotherCollection: CONFIRMATION_SCREEN.TRACK_ANOTHER_COLLECTION,
  })
)(CompleteStepComponent);

export default CompleteStep;
