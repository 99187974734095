import { lifecycle } from 'recompose';

import { logFunctionalityId } from '../apis';

const withFunctionalityLog = funcId =>
  lifecycle({
    async componentDidMount() {
      const functionalityId =
        typeof funcId === 'function' ? funcId(this.props) : funcId;

      try {
        await logFunctionalityId(functionalityId);
      } catch (e) {
        // ignore any errors
      }
    },
  });

export default withFunctionalityLog;
