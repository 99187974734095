import { DEFAULT_DATE_FORMAT } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeCollectionOption } from '../../../../store/orm/Collection/actions';
import type { ChangeCollectionOptionOptions } from '../../../../apis';
import type { AuthorizedCollection } from '../../../../types/Collection';
import { getNotificationDetails } from '../../../../models/collection';
import { parseDate } from '../../../../utils/moment';

type DataProps = {
  day: string,
  notificationDetails?: Object,
};

export const changeCollectionPreferenceDay = (
  collection: AuthorizedCollection,
  { day, notificationDetails }: DataProps,
  options: ChangeCollectionOptionOptions
) =>
  changeCollectionOption(
    collection.collectionCode,
    {
      collectionDate: parseDate(day, DEFAULT_DATE_FORMAT),
      notificationDetails:
        notificationDetails || getNotificationDetails(collection),
    },
    options
  );
