// @flow

import api from './api';

type RequestBody = {|
  functionalityId: string,
|};

export const logFunctionalityId = (functionalityId: string) => {
  return api.post<_, RequestBody, any>({
    path: `/logs`,
    body: {
      functionalityId,
    },
  });
};
