// @flow
import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';

import { withCollectionUpdate } from '../../hocs';
import type { AuthorizedCollection } from '../../../../types/Collection';
import { CAN_STEPS } from '../../steps';
import { withCollection } from '../../../Collection/hocs';
import { hasCollectionNotificationDetails } from '../../../../models/collection';
import GetContactDetailsStep from './GetContactDetailsStep';
import { cancelCollection } from './actions';

type Props = {
  collection: AuthorizedCollection,
  updateCollection: Function,
  onSubmitFailure: Function,
};

const ChangeCollectionDateComponent = ({
  collection,
  updateCollection,
  onSubmitFailure,
}: Props) => (
  <Wizard
    onSubmit={updateCollection}
    initialStep={
      hasCollectionNotificationDetails(collection)
        ? CAN_STEPS.COMPLETE
        : CAN_STEPS.GET_CONTACTS
    }
    onSubmitFailure={onSubmitFailure}
  >
    <Step
      stepId={CAN_STEPS.GET_CONTACTS}
      component={GetContactDetailsStep}
      collection={collection}
    />
  </Wizard>
);

const ChangeCollectionDate = compose(
  withRouter,
  withCollection,
  withCollectionUpdate({
    actionCode: ENUM.COLLECTION_ACTION_CODE.CAN,
    changeCollectionAction: cancelCollection,
  })
)(ChangeCollectionDateComponent);

export default ChangeCollectionDate;
