// @flow

import type { Address } from '@dpdgroupuk/fmx-ui/types';

import { changeCollectionOption } from '../../../../store/orm/Collection/actions';
import type { ChangeCollectionOptionOptions } from '../../../../apis';
import type { AuthorizedCollection } from '../../../../types/Collection';
import { getNotificationDetails } from '../../../../models/collection';
import { calculateChangeCollectionDate } from '../../model';

type DataProps = {
  day?: string,
  imageKey?: string,
  address: Address,
  notificationDetails?: Object,
};

export const changeCollectionDateAndNeighbourAddress = (
  collection: AuthorizedCollection,
  { day, address, notificationDetails, imageKey }: DataProps,
  options: ChangeCollectionOptionOptions
) =>
  changeCollectionOption(
    collection.collectionCode,
    {
      addressUdprn: address.udprn,
      ...(imageKey && { imageKey }),
      collectionDate: calculateChangeCollectionDate(
        day,
        collection.collectionDate
      ),
      notificationDetails:
        notificationDetails || getNotificationDetails(collection),
    },
    options
  );
