// @flow
import FetchClient from '@dpdgroupuk/fetch-client';
import { API_HOST } from '../constants/app';

const fetchClient: FetchClient = new FetchClient({
  mode: 'cors',
  credentials: 'include',
  baseUrl: API_HOST,
});

export default fetchClient;
