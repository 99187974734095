// @flow

const COMPLETE = 'Complete';
const SELECT_DAY = 'SelectDay';
const GET_CONTACTS = 'GetContacts';

export const CAN_STEPS = {
  GET_CONTACTS,
  COMPLETE,
};

export const CHD_STEPS = {
  SELECT_DAY,
  GET_CONTACTS,
};

export const CFN_STEPS = {
  SELECT_DAY,
  SELECT_NEIGHBOUR: 'SelectNeighbour',
  UPLOAD_PHOTO: 'UploadPhoto',
  GET_CONTACTS,
};

export const SFP_STEPS = {
  SELECT_DAY,
  SELECT_SAFE_PLACE: 'SelectSafePlace',
  DESCRIBE_SAFE_PLACE: 'DescribeSafePlace',
  UPLOAD_SAFE_PLACE_PHOTO: 'UploadSafePlacePhoto',
  GET_CONTACTS,
};
