export const formatPathName = (path, params) =>
  Object.keys(params).reduce(
    (newPath, paramKey) => newPath.replace(`:${paramKey}`, params[paramKey]),
    path
  );

export const pathToAction = ({ history }: any, [title, path, actionId]) => [
  title,
  () => (path.href ? window.open(path.href) : history.push(path)),
  actionId,
];
