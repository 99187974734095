// @flow

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncAction } from '@dpdgroupuk/redux-action-creator';
import { apiService, fetchTimeoutHandler } from '../../services';

type ActionTypes = {|
  FETCH_ACTIONS_DATES_BY_CODE: AsyncAction,
  FETCH_LOCATIONS_BY_POSTCODE: AsyncAction,
|};

export const ACTIONS = createActionTypes<ActionTypes>('ACTION', {
  FETCH_ACTIONS_DATES_BY_CODE: createAsyncActionTypes(
    'FETCH_ACTIONS_DATES_BY_CODE'
  ),
  FETCH_LOCATIONS_BY_POSTCODE: createAsyncActionTypes(
    'FETCH_LOCATIONS_BY_POSTCODE'
  ),
});

export const fetchActionsDatesByCode = createAsyncAction(
  (collectionCode: string, actionCode: string) =>
    fetchTimeoutHandler(
      apiService.getActionsDates(collectionCode, actionCode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_ACTIONS_DATES_BY_CODE
);

export const fetchLocationsByPostcode = createAsyncAction(
  (postcode: string) =>
    fetchTimeoutHandler(apiService.getLocations(postcode)).then(
      ({ data }) => data
    ),
  ACTIONS.FETCH_LOCATIONS_BY_POSTCODE
);
