import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/analytics';

const {
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

firebase.initializeApp({
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  apiKey: REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const DEFAULT_REMOTE_CONFIG_SETTINGS = {
  minimumFetchIntervalMillis: 60000,
};

export const initializeRemoteConfig = () => {
  firebase.remoteConfig().settings = DEFAULT_REMOTE_CONFIG_SETTINGS;
  return firebase.remoteConfig();
};

export default firebase;
