// @flow

import * as ENUM from '@dpdgroupuk/redback-enums';
import api from './api';
import type { Collection } from '../types/Collection';
import { API_HOST } from '../constants/app';

export const getCollectionByCollectionCode = (collectionCode: string) => {
  return api.get<_, Collection>({
    path: `/collections/${collectionCode}`,
  });
};

export const getSearchCollectionByReferenceNumber = (
  referenceNumber: string,
  postcode: string
) => {
  return api.get<_, Collection>({
    path: '/collections',
    query: {
      referenceNumber,
      postcode,
    },
  });
};

export const uploadCollectionPhoto = async (
  image: any,
  collectionCode: string,
  actionCode: string = ENUM.COLLECTION_ACTION_CODE.SFP,
  token: string
) => {
  const formData = new FormData();
  formData.append('image', image);

  // TODO improve to use standard fetch client api.post({})
  return fetch(
    `${API_HOST}/collections/${collectionCode}/image/${actionCode}`,
    {
      method: 'POST',
      body: formData,
      credentials: 'include',
      headers: {
        'g-recaptcha-response': token,
      },
    }
  )
    .then(data => data.json())
    .then(({ data }) => data);
};
