import { compose, withProps } from 'recompose';
import { StatusCodes } from 'http-status-codes';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  formatPostcodeForServer,
  formatReferenceNumberForServer,
  parseRefNumberAndPostcode,
} from '../models';
import {
  fetchSearchCollectionByReferenceNumber,
  login,
} from '../../../store/actions/collections';
import { REFERENCE_ENTRY, SEARCH } from '../../../constants/routes';
import { getLocationTracking } from '../../Collection/actions/location';
import { ACTION } from '../../../constants/recaptcha';
import { REFERENCE_NUMBER_COULD_NOT_BE_FOUND } from '../../../constants/message';

export const withSearchSubmitHandler = compose(
  connect(
    null,
    {
      login,
      search: fetchSearchCollectionByReferenceNumber,
    }
  ),
  withProps(
    ({ modal, overlay, history, location, reCaptchaConfig, login, search }) => {
      const onSearchSubmitFailure = e => {
        modal.showModal({
          title:
            e.statusCode === StatusCodes.BAD_REQUEST ||
            e.statusCode === StatusCodes.NOT_FOUND
              ? REFERENCE_NUMBER_COULD_NOT_BE_FOUND
              : e.message,
          description: 'Please check your details and try again.',
        });
      };
      return {
        onSearchSubmitFailure,
        onSearchSubmit: overlay.showWhile(async (values: Object) => {
          const { postcode, referenceNumber } = parseRefNumberAndPostcode(
            values
          );

          const formattedNumber = formatReferenceNumberForServer(
            referenceNumber
          );
          const formattedPostcode = formatPostcodeForServer(postcode);

          const { origin } = queryString.parse(location.search);

          const token = await reCaptchaConfig.executeRecaptchaAsync(
            ACTION.REFERENCE
          );

          try {
            const foundParcels = await search(
              formattedNumber,
              formattedPostcode,
              origin
            );
            if (foundParcels.collectionCode) {
              formattedPostcode &&
                (await login(
                  foundParcels.collectionCode,
                  formattedPostcode,
                  token,
                  origin
                ));
              history.push(
                getLocationTracking({
                  collectionId: foundParcels.collectionCode,
                })
              );
            } else
              history.push({
                pathname: SEARCH,
                search: `reference=${formattedNumber}&postcode=${formattedPostcode}`,
                state: {
                  from: REFERENCE_ENTRY,
                },
              });
          } catch (e) {
            onSearchSubmitFailure(e);
          }
        }),
      };
    }
  )
);
