// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Table from '@dpdgroupuk/fmx-ui/components/Table';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatDayMonthYearTime } from '@dpdgroupuk/fmx-ui/utils/date';

import type {
  AuthorizedCollection,
  CollectionEvent,
} from '../../types/Collection';
import {
  COLLECTION_HISTORY,
  DATE_AND_TIME,
  LOCATION,
  DESCRIPTION,
} from '../../constants/message';
import { getEventDateTime } from '../../models/event';
import { EVENT_DATE_TIME_FORMAT } from '../../constants/date';

const useStyles = makeStyles(({ palette, typography }) => ({
  container: {
    marginTop: typography.pxToRem(100),
  },
  topContainer: {
    paddingLeft: 30,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: typography.pxToRem(30),
  },
  label: {
    minWidth: typography.pxToRem(245),
    fontSize: typography.pxToRem(20),
    letterSpacing: 0.44,
    color: palette.text.secondary,
    lineHeight: 1,
  },
  info: {
    fontSize: typography.pxToRem(15),
    fontWeight: 300,
    letterSpacing: 0.44,
    color: palette.text.primary,
    flexWrap: 'wrap',
  },
  title: {
    fontSize: typography.pxToRem(20),
    letterSpacing: 0.44,
    color: palette.text.secondary,
    margin: `${typography.pxToRem(40)} 0 ${typography.pxToRem(30)}`,
  },
  locationCellHeader: {
    paddingLeft: 45,
  },
  column: {
    minWidth: 200,
  },
}));

type Props = {
  collection: AuthorizedCollection,
};

const CollectionEvents = ({ collection }: Props) => {
  const styles = useStyles({ width: window.innerWidth });
  const collectionEvents = collection.collectionEvents.sort(
    (eventOne, eventTwo) =>
      getEventDateTime(eventTwo) - getEventDateTime(eventOne)
  );

  return (
    <Container maxWidth="md" className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.infoContainer}>
          <Typography className={styles.label}>Collection address:</Typography>
          <Typography className={styles.info}>
            {getAddressWithPostcode(collection.collectionDetails.address)}
          </Typography>
        </div>
        <div className={styles.infoContainer}>
          <Typography className={styles.label}>
            Your collection number:
          </Typography>
          <Typography className={styles.info}>
            {collection.collectionNumber}
          </Typography>
        </div>
        <Typography className={styles.title}>{COLLECTION_HISTORY}</Typography>
      </div>
      <Table
        data={collectionEvents}
        titles={[
          { value: DATE_AND_TIME },
          { value: LOCATION, className: styles.locationCellHeader },
          { value: DESCRIPTION },
        ]}
        rowMap={(row: CollectionEvent) => [
          {
            className: styles.column,
            value: formatDayMonthYearTime(
              `${row.date} ${row.time}`,
              EVENT_DATE_TIME_FORMAT
            ),
          },
          {
            className: styles.column,
            value: row.depotName,
          },
          {
            value: row.text,
          },
        ]}
      />
    </Container>
  );
};

export default CollectionEvents;
