// @flow
import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TrackerProvider } from '@dpdgroupuk/react-event-tracker';
import { Provider as DialogProvider } from '@dpdgroupuk/fmx-ui/components/Dialog';
import OverlayProvider from '@dpdgroupuk/fmx-ui/components/Overlay';
import { SnackbarProvider } from 'notistack';

import { EnvProvider } from '@dpdgroupuk/fmx-ui/env';
// must be exactly in that order: firebase, analytics
import { initializeRemoteConfig } from './utils/firebase';

import tracker from './utils/analytics';
import Routes from './Routes';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import { ConfigProvider } from './components/RemoteConfig';
import GlobalCSS from './components/theme';
import { API_HOST, ENTITY_PREFIX } from './constants/app';
import ThemeAndEventProvider from './ThemeAndEventProvider';

const App = () => {
  return (
    <Provider store={store}>
      <EnvProvider value={{ API_HOST, ENTITY_PREFIX }}>
        <ConfigProvider remoteConfig={initializeRemoteConfig()}>
          <TrackerProvider tracker={tracker}>
            <ThemeAndEventProvider>
              <OverlayProvider position="fixed">
                <DialogProvider>
                  <GlobalCSS />
                  <CssBaseline />
                  <ErrorBoundary>
                    <SnackbarProvider
                      maxSnack={3}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <Routes />
                    </SnackbarProvider>
                  </ErrorBoundary>
                </DialogProvider>
              </OverlayProvider>
            </ThemeAndEventProvider>
          </TrackerProvider>
        </ConfigProvider>
      </EnvProvider>
    </Provider>
  );
};

export default App;
