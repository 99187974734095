import * as React from 'react';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { withProps } from 'recompose';

import BackToTrackingMap from '@dpdgroupuk/fmx-ui/components/BackToTrackingMap';
import { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import * as ENUM from '@dpdgroupuk/redback-enums';

import { goToTracking } from '../../../historyActions';
import CompleteStepCHD from '../ChangeCollectionDate/CompleteStep';
import CompleteStepCFN from '../CollectFromNeighbour/CompleteStep';
import CompleteStepSFP from '../CollectFromSafePlace/CompleteStep';
import CompleteStepCAN from '../CancelСollection/CompleteStep';
import type { AuthorizedCollection } from '../../../../types/Collection';

type CompletedScreenProps = {
  actionCode?: string,
  data?: Object,
  collection: AuthorizedCollection,
  trackAnotherCollection: Function,
};

const CompletedScreenRoutes = (props: CompletedScreenProps) => {
  const { actionCode } = props;

  switch (actionCode) {
    case ENUM.COLLECTION_ACTION_CODE.SFP:
      return <CompleteStepSFP {...props} />;
    case ENUM.COLLECTION_ACTION_CODE.CFN:
      return <CompleteStepCFN {...props} />;
    case ENUM.COLLECTION_ACTION_CODE.CHD:
      return <CompleteStepCHD {...props} />;
    case ENUM.COLLECTION_ACTION_CODE.CAN:
      return <CompleteStepCAN {...props} />;
    default:
      return <Redirect to="/" />;
  }
};

type Props = {
  ...CompletedScreenProps,
  handleGoToTracking: Function,
};

const CompletedScreen = ({ handleGoToTracking, ...props }: Props) => (
  <BackToTrackingMap onClick={handleGoToTracking}>
    <Step component={CompletedScreenRoutes} {...props} />
  </BackToTrackingMap>
);

export default compose(
  withRouter,
  withProps(props => {
    const { actionCode, data, collection } = props.history.location.state || {};
    const completedProps = { ...props, actionCode, data, collection };
    const handleGoToTracking = () => goToTracking(completedProps);

    return {
      ...completedProps,
      handleGoToTracking,
    };
  })
)(CompletedScreen);
