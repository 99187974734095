import React from 'react';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import ContactDetailsForm from '@dpdgroupuk/fmx-ui/components/ContactDetailsForm';
import { WIZARD_CONTACTS } from '../../../constants/analytics';

const ContactDetailsFormComponent = props => {
  useGoogleReCaptchaVisibleToggle();

  return <ContactDetailsForm {...props} analytics={WIZARD_CONTACTS} />;
};

export default ContactDetailsFormComponent;
