// @flow
import type { Driver, DriverFeedback } from '../types/Driver';

import api from './api';

export const getDriverByDriverCode = (driverCode: string) =>
  api.get<_, Driver>({
    path: `/drivers/${driverCode}`,
  });

export const getDriverFeedbackByDriverCode = (driverCode: string) =>
  api.get<_, DriverFeedback>({
    path: `/drivers/${driverCode}/rating`,
  });
