import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import last from 'lodash/last';

import { createSafePlace } from '@dpdgroupuk/fmx-ui/components/SafePlaceItem';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { useOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';
import { useGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import { noop } from '../../../../utils/function';
import { SFP_STEPS } from '../../steps';
import type { AuthorizedCollection } from '../../../../types/Collection';
import ChangeSafePlaces from '../../components/ChangeSafePlaces';
import { uploadCollectionPhoto } from '../../../../apis';
import ChangeDate from '../../components/ChangeDate';
import { hasCollectionNotificationDetails } from '../../../../models/collection';
import DropZone from '../../../../components/DropZone';
import {
  CLOSE,
  PROBLEM_COMPLETING_REQUEST,
  SFP_DND_TEXT,
} from '../../../../constants/message';
import { ACTION } from '../../../../constants/recaptcha';
import { WIZARD_COMMON } from '../../../../constants/analytics';

type Props = {
  collection: AuthorizedCollection,
  data: Object,
  dates: Array<string>,
  onSubmit: Function,
  onSkip: Function,
  onReplacePhoto: Function,
  onDateChange: Function,
  onSafePlaceChange: Function,
  addStepData: Function,
};

const UploadSafePlaceStep = ({
  collection,
  data,
  dates,
  onSubmit,
  onSkip,
  onReplacePhoto,
  onDateChange,
  onSafePlaceChange,
  addStepData,
}: Props) => {
  const { showWhile } = useOverlay();
  const { showModal } = useModal();
  const { executeRecaptchaAsync } = useGoogleReCaptcha();
  const { photoUrl } = data;

  const uploadPhoto = React.useCallback(
    showWhile(async acceptedFiles => {
      try {
        const token = await executeRecaptchaAsync(ACTION.UPLOAD_SFP_PHOTO);
        const { imageKey, photoUrl } = await uploadCollectionPhoto(
          last(acceptedFiles),
          collection.collectionCode,
          ENUM.COLLECTION_ACTION_CODE.SFP,
          token
        );
        addStepData({
          photoUrl,
          imageKey,
        });
      } catch (error) {
        showModal({
          title: PROBLEM_COMPLETING_REQUEST,
          description: null,
          buttonText: CLOSE,
        });
      }
    }),
    [addStepData, executeRecaptchaAsync, uploadCollectionPhoto]
  );

  return (
    <>
      <ChangeDate
        addStepData={addStepData}
        day={dates && dates[0]}
        dates={dates}
        collection={collection}
        onDateChange={onDateChange}
      />
      <ChangeSafePlaces data={data} onSafePlaceChange={onSafePlaceChange} />
      <DropZone
        description={SFP_DND_TEXT}
        onSubmit={onSubmit}
        onSkip={onSkip}
        onReplacePhoto={onReplacePhoto}
        onDropAccepted={uploadPhoto}
        photoUrl={photoUrl}
      />
    </>
  );
};

UploadSafePlaceStep.defaultProps = {
  data: { day: '' },
  addStepData: noop,
  safePlaces: [
    createSafePlace(ENUM.SAFE_PLACE_TYPE.FPC),
    createSafePlace(ENUM.SAFE_PLACE_TYPE.GAR),
    createSafePlace(ENUM.SAFE_PLACE_TYPE.OBL),
    createSafePlace(ENUM.SAFE_PLACE_TYPE.RPC),
  ],
};

export default compose(
  withProps(({ collection, submitWizard, goToStep, addStepData }) => {
    const onSubmit = hasCollectionNotificationDetails(collection)
      ? submitWizard
      : () => goToStep(SFP_STEPS.GET_CONTACTS);

    return {
      onSubmit,
      onSkip: () => {
        addStepData({
          photoUrl: '',
        });
        onSubmit();
      },
      onReplacePhoto: () =>
        addStepData({
          photoUrl: '',
          imageKey: '',
        }),
      onDateChange: () => goToStep(SFP_STEPS.SELECT_DAY),
      onSafePlaceChange: () => goToStep(SFP_STEPS.SELECT_SAFE_PLACE),
    };
  }),
  withTrackProps({
    onDateChange: WIZARD_COMMON.ON_DATE_CHANGE,
  })
)(UploadSafePlaceStep);
