// @flow
import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import type { AuthorizedCollection } from '../../../../types/Collection';
import { CHD, trackable } from '../../../../constants/analytics';
import * as MESSAGES from '../../../../constants/message';
import SelectDayStep from './SelectDayStep';
import GetContactDetailsStep from './GetContactDetailsStep';
import { fetchActionsDatesByCode } from '../../actions';
import { CHD_STEPS } from '../../steps';
import { withCollectionUpdate } from '../../hocs';
import { changeCollectionPreferenceDay } from './actions';
import { withCollection } from '../../../Collection/hocs';
import { hasCollectionNotificationDetails } from '../../../../models/collection';
import {
  getCollectionOptionHeaderControlActions,
  getCollectionOptionSpeedDealControlActions,
} from '../../../controlActions';
import withFetch from '../../../../HOCs/withFetch';

type Props = {
  collection: AuthorizedCollection,
  dates: Array<string>,
  updateCollection: Function,
  onSubmitFailure: Function,
};

const ChangeCollectionDateComponent = ({
  collection,
  dates,
  updateCollection,
  onSubmitFailure,
}: Props) => (
  <Wizard
    onSubmit={updateCollection}
    initialStep={CHD_STEPS.SELECT_DAY}
    onSubmitFailure={onSubmitFailure}
  >
    <Step
      stepId={CHD_STEPS.SELECT_DAY}
      component={SelectDayStep}
      dates={dates}
      isNotificationDetailsAvailable={hasCollectionNotificationDetails(
        collection
      )}
    />
    <Step
      stepId={CHD_STEPS.GET_CONTACTS}
      component={GetContactDetailsStep}
      collection={collection}
    />
  </Wizard>
);

const ChangeCollectionDate = compose(
  withRouter,
  withCollection,
  withFetch(({ match }) =>
    fetchActionsDatesByCode(
      match.params.collectionId,
      ENUM.COLLECTION_ACTION_CODE.CHD
    )
  ),
  withProps(props => ({
    dates: props.fetchedData,
  })),
  withCollectionUpdate({
    actionCode: ENUM.COLLECTION_ACTION_CODE.CHD,
    changeCollectionAction: changeCollectionPreferenceDay,
  }),
  withTrackProps({
    updateCollection: CHD.SUBMIT,
  }),
  withPageControl({
    title: MESSAGES.CHANGE_COLLECTION_DAY,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(CHD),
    headerMenuActions: getCollectionOptionHeaderControlActions,
    speedDealActions: getCollectionOptionSpeedDealControlActions,
  })
)(ChangeCollectionDateComponent);

export default ChangeCollectionDate;
