// @flow
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from '@dpdgroupuk/redux-action-creator';
import type { AsyncDispatch } from '@dpdgroupuk/redux-action-creator';

import { apiService, fetchTimeoutHandler } from '../../services';
import { CollectionActions } from '../orm/Collection';
import { getSearchCollectionByReferenceNumber } from '../../apis/collection';
import { createLoadAsyncAction } from '../../utils/actionCreator';
import { getCollectionData } from '../orm/Collection/selectors';

export const ACTIONS = createActionTypes('SEARCH_COLLECTION', {
  FETCH_SEARCH_COLLECTION_BY_REFERENCE: createAsyncActionTypes(
    'FETCH_SEARCH_COLLECTION_BY_REFERENCE'
  ),
  LOGIN: createAsyncActionTypes('LOGIN'),
});

export const fetchSearchCollectionByReferenceNumber = createAsyncAction(
  (referenceNumber: string, postcode: string) =>
    fetchTimeoutHandler(
      getSearchCollectionByReferenceNumber(referenceNumber, postcode)
    ).then(({ data }) => data),
  ACTIONS.FETCH_SEARCH_COLLECTION_BY_REFERENCE
);

export const login = createAsyncAction(
  (collectionCode: string, postcode: string, token: string) =>
    fetchTimeoutHandler(apiService.login(collectionCode, postcode, token)).then(
      ({ data }) => data
    ),
  ACTIONS.LOGIN
);

export const fetchCollection = (collectionCode: string) =>
  CollectionActions.fetchCollectionByCode(collectionCode);

export const loginAndFetchCollection = (
  collectionCode: string,
  postcode: string,
  token: string
) => (dispatch: AsyncDispatch) =>
  dispatch(login(collectionCode, postcode, token)).then(() =>
    dispatch(CollectionActions.fetchCollectionByCode(collectionCode))
  );

export const fetchCacheableCollection = createLoadAsyncAction(
  (state, { match }) => getCollectionData(state, match.params.collectionId),
  ({ match }) => fetchCollection(match.params.collectionId)
);
