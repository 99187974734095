// @flow
import * as React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import Wizard, { Step } from '@dpdgroupuk/fmx-ui/components/Wizard';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { withTrackProps } from '@dpdgroupuk/react-event-tracker';

import type { AuthorizedCollection } from '../../../../types/Collection';
import { CFN, trackable } from '../../../../constants/analytics';
import * as MESSAGES from '../../../../constants/message';
import SelectDayStep from './SelectDayStep';
import SelectNeighbourStep from './SelectNeighbourStep';
import { fetchActionsDatesByCode } from '../../actions';
import { CFN_STEPS } from '../../steps';
import { withCollectionUpdate } from '../../hocs';
import { changeCollectionDateAndNeighbourAddress } from './actions';
import { withCollection } from '../../../Collection/hocs';
import GetContactDetailsStep from './GetContactDetailsStep';
import UploadPhotoStep from './UploadPhotoStep';
import {
  getCollectionOptionHeaderControlActions,
  getCollectionOptionSpeedDealControlActions,
} from '../../../controlActions';
import withFetch from '../../../../HOCs/withFetch';

type Props = {
  collection: AuthorizedCollection,
  dates: Array<string>,
  updateCollection: Function,
  onSubmitFailure: Function,
};

const ChangeCollectionDateComponent = ({
  collection,
  dates,
  updateCollection,
  onSubmitFailure,
}: Props) => (
  <Wizard
    onSubmit={updateCollection}
    initialStep={CFN_STEPS.SELECT_NEIGHBOUR}
    onSubmitFailure={onSubmitFailure}
  >
    <Step
      stepId={CFN_STEPS.SELECT_NEIGHBOUR}
      component={SelectNeighbourStep}
      collection={collection}
      dates={dates}
      isSelectDayStepAvailable={!isEmpty(dates)}
    />
    <Step
      stepId={CFN_STEPS.SELECT_DAY}
      component={SelectDayStep}
      dates={dates}
      collectionDate={collection.collectionDate}
    />
    <Step
      stepId={CFN_STEPS.UPLOAD_PHOTO}
      dates={dates}
      component={UploadPhotoStep}
      collection={collection}
    />
    <Step
      stepId={CFN_STEPS.GET_CONTACTS}
      component={GetContactDetailsStep}
      collection={collection}
    />
  </Wizard>
);

const ChangeCollectionDate = compose(
  withRouter,
  withCollection,
  withFetch(({ match }) =>
    fetchActionsDatesByCode(
      match.params.collectionId,
      ENUM.COLLECTION_ACTION_CODE.CFN
    )
  ),
  withProps(props => ({
    dates: props.fetchedData,
  })),
  withCollectionUpdate({
    actionCode: ENUM.COLLECTION_ACTION_CODE.CFN,
    changeCollectionAction: changeCollectionDateAndNeighbourAddress,
  }),
  withTrackProps({
    updateCollection: CFN.SUBMIT,
  }),
  withPageControl({
    title: MESSAGES.COLLECT_FROM_NEIGHBOUR,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(CFN),
    headerMenuActions: getCollectionOptionHeaderControlActions,
    speedDealActions: getCollectionOptionSpeedDealControlActions,
  })
)(ChangeCollectionDateComponent);

export default ChangeCollectionDate;
