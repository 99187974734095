// @flow

import { compose } from 'redux';
import { withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import { DIALOG_TYPE } from '@dpdgroupuk/fmx-ui/constants/dialogTypes';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';

import {
  CANCEL_COLLECTION,
  PROVIDE_CONTACT_INFO,
  CONFIRM_CANCEL_COLLECTION,
} from '../../../../constants/message';
import { getCollectionNotificationDetails } from '../../../../models/collection';
import {
  CANCEL_MODAL,
  COLLECTION_OPTIONS,
  trackable,
} from '../../../../constants/analytics';
import {
  getCollectionOptionHeaderControlActions,
  getCollectionOptionSpeedDealControlActions,
} from '../../../controlActions';
import ContactDetailsFormComponent from '../../components/GetContactDetailsStep';

const ContactDetailsStep = compose(
  withRouter,
  withPageControl({
    title: CANCEL_COLLECTION,
    backgroundImage: IMAGES.MAP,
    trackable: trackable(COLLECTION_OPTIONS),
    headerMenuActions: getCollectionOptionHeaderControlActions,
    speedDealActions: getCollectionOptionSpeedDealControlActions,
  }),
  withProps(({ collection, addStepData, submitWizard }) => {
    const { showModal } = useModal();
    return {
      title: PROVIDE_CONTACT_INFO,
      initialValues: {
        contactName: getCollectionNotificationDetails(collection).contact,
      },
      onChange: values => {
        addStepData({
          notificationDetails: {
            contactName: values.contactName,
            email: values.contactEmail,
            sms: values.contactPhone,
          },
        });
      },
      onSubmit: () =>
        showModal({
          type: DIALOG_TYPE.CONFIRMATION,
          title: CONFIRM_CANCEL_COLLECTION,
          onConfirm: submitWizard,
          analytics: CANCEL_MODAL,
        }),
    };
  })
)(ContactDetailsFormComponent);

export default ContactDetailsStep;
