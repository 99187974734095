import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import MobileTable from '@dpdgroupuk/fmx-ui/components/MobileTable';
import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import { getAddressWithPostcode } from '@dpdgroupuk/fmx-ui/utils/address';
import { formatDayMonthYearTime } from '@dpdgroupuk/fmx-ui/utils/date';

import {
  COLLECTION_HISTORY,
  DATE_AND_TIME,
  LOCATION,
} from '../../constants/message';
import type {
  AuthorizedCollection,
  CollectionEvent,
} from '../../types/Collection';
import { getEventDateTime } from '../../models/event';
import { EVENT_DATE_TIME_FORMAT } from '../../constants/date';

const useStyles = makeStyles(({ palette, typography }) => ({
  container: {
    marginTop: typography.pxToRem(120),
  },
  infoContainer: ({ width }) => ({
    display: 'flex',
    flexDirection: width < 350 ? 'column' : 'row',
    paddingBottom: typography.pxToRem(13),
  }),
  label: {
    minWidth: typography.pxToRem(205),
    fontSize: typography.pxToRem(17),
    letterSpacing: 0.38,
    color: palette.text.secondary,
    lineHeight: 1.1,
  },
  info: {
    fontSize: typography.pxToRem(15),
    fontWeight: 300,
    letterSpacing: 0.44,
    color: palette.text.primary,
    flexWrap: 'wrap',
  },
  title: {
    textAlign: 'center',
    fontSize: typography.pxToRem(17),
    letterSpacing: 0.44,
    lineHeight: 0.86,
    color: palette.text.secondary,
    margin: `${typography.pxToRem(30)} 0 ${typography.pxToRem(30)}`,
  },
}));

type Props = {
  collection: AuthorizedCollection,
};

const ParcelEventsMobile = ({ collection }: Props) => {
  const styles = useStyles({ width: window.innerWidth });
  const collectionEvents = collection.collectionEvents.sort(
    (eventOne, eventTwo) =>
      getEventDateTime(eventTwo) - getEventDateTime(eventOne)
  );
  const parcelRows = collectionEvents.map((event: CollectionEvent) => {
    return [
      {
        title: DATE_AND_TIME,
        value: formatDayMonthYearTime(
          `${event.date} ${event.time}`,
          EVENT_DATE_TIME_FORMAT
        ),
      },
      {
        title: LOCATION,
        value: event.depotName,
      },
      {
        value: event.text,
      },
    ];
  });

  return (
    <FluidContainer>
      <Grid className={styles.container}>
        <div className={styles.infoContainer}>
          <Typography className={styles.label}>Collection address:</Typography>
          <Typography className={styles.info}>
            {getAddressWithPostcode(collection.collectionDetails.address)}
          </Typography>
        </div>
        <div className={styles.infoContainer}>
          <Typography className={styles.label}>
            Your collection number:
          </Typography>
          <Typography className={styles.info}>
            {collection.collectionNumber}
          </Typography>
        </div>
        <Typography className={styles.title}>{COLLECTION_HISTORY}</Typography>
        <MobileTable rows={parcelRows} />
      </Grid>
    </FluidContainer>
  );
};

export default ParcelEventsMobile;
