// @flow
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: 250,
    objectFit: 'contain',
  },
  border: {
    border: 'solid 5px #ffffff',
    boxSizing: 'content-box',
  },
}));

type Props = {
  withBorder?: boolean,
  className?: Object,
  photoUrl: string,
};

const ImagePreview = ({ withBorder, photoUrl, className }: Props) => {
  const styles = useStyles();
  if (!photoUrl) {
    return <></>;
  }
  return (
    <img
      className={clsx(className, styles.image, withBorder && styles.border)}
      src={photoUrl}
      alt=""
    />
  );
};

export default ImagePreview;
