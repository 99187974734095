// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { InputFormField } from '@dpdgroupuk/fmx-ui/components/Input';
import Button from '@dpdgroupuk/fmx-ui/components/Button';
import FormWithSubmit from '@dpdgroupuk/fmx-ui/components/FormWithSubmit';
import {
  required,
  validatePostcodeWithMessage,
} from '@dpdgroupuk/fmx-ui/utils/validation';

import { ENTER_POSTCODE, SUBMIT } from '../../../../../constants/message';

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  container: {
    width: '100%',
    paddingTop: typography.pxToRem(40),
  },
  title: {
    marginBottom: typography.pxToRem(40),
    fontSize: typography.pxToRem(20),
    lineHeight: 1.5,
    letterSpacing: 0.45,
    fontWeight: 400,
    textAlign: 'center',
    maxWidth: typography.pxToRem(400),
    [breakpoints.down('xs')]: {
      fontSize: typography.pxToRem(15),
      maxWidth: typography.pxToRem(296),
      lineHeight: 1.69,
      marginBottom: typography.pxToRem(35),
    },
  },
  postcode: {
    flex: 1,
    marginRight: typography.pxToRem(20),
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  handleSubmit: Function => Function,
  onPostcodeSubmit: Function,
  valid: boolean,
};

const validatePostcode = [required(), validatePostcodeWithMessage];

const TrackingPostcodeForm = ({
  handleSubmit,
  valid,
  onPostcodeSubmit,
}: Props) => {
  const styles = useStyles();

  return (
    <Grid container justify="center" className={styles.container}>
      <FormWithSubmit
        onSubmit={handleSubmit(onPostcodeSubmit)}
        className={styles.form}
      >
        <Grid>
          <Typography className={styles.title}>{ENTER_POSTCODE}</Typography>
        </Grid>
        <Grid className={styles.inputContainer}>
          <Field
            component={InputFormField}
            name="postcode"
            placeholder="Postcode"
            validate={validatePostcode}
            className={styles.postcode}
            inputProps={{
              maxLength: 8,
            }}
          />
          <Button type="submit" disabled={!valid}>
            {SUBMIT}
          </Button>
        </Grid>
      </FormWithSubmit>
    </Grid>
  );
};

export default reduxForm({ form: 'trackingPostcode' })(TrackingPostcodeForm);
